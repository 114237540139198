import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['customRadioButton']

  private addressInputComponent: HTMLElement;
  declare customRadioButtonTarget: HTMLInputElement;

  constructor(element: HTMLElement) {
    super(element);

    this.addressInputComponent = document.querySelector('.address-input-component') as HTMLElement;
  }

  public enableCustomAddress(): void {
    this.addressInputComponent.classList.toggle('hide', false);
  }

  public disableCustomAddress(): void {
    const hideInput = this.shouldHideInputComponent();

    this.addressInputComponent.classList.toggle('hide', hideInput);
  }

  private shouldHideInputComponent(): void{
    const postcardCheckbox = document.querySelector('#postcard_notice_selection > input[type=checkbox]') as HTMLInputElement;

    return !postcardCheckbox.checked;
  }
}
