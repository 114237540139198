
import { Controller } from 'stimulus';
import axios from 'axios';
import { ErrorMessages } from './error_messages';

export default class extends Controller {
  static targets = [
    'errorContainer',
    'form',
    'additionalKeysCountInput',
    'availableAccessKeysCount',
    'submitButton'
  ];

  declare errorContainerTarget: HTMLElement;
  declare readonly formTarget: HTMLFormElement;
  declare additionalKeysCountInputTarget: HTMLInputElement;
  declare readonly availableAccessKeysCountTarget: HTMLSpanElement;
  declare submitButtonTarget: HTMLButtonElement;

  private readonly onlyNumbersWithNavigationKeysRegex = /[0-9]|(^Backspace$)|(^Arrow+[Up|Down|Right|Left])|(^Delete$)|(^Tab$)/;
  private availableAccessKeysCount: number;

  connect(): void {
    this.availableAccessKeysCount = parseInt(this.availableAccessKeysCountTarget.getAttribute('data-count'));
  }

  public numbersOnly(event: KeyboardEvent): void {
    if (!event.key.match(this.onlyNumbersWithNavigationKeysRegex)) event.preventDefault();
  }

  public additionalKeysCountUpdate(event: Event): void {
    const value: string = this.additionalKeysCountInputTarget.value;
    this.hideError();

    if (value === '') {
      this.submitButtonTarget.disabled = true;
    } else {
      const proposedKeysCount: number = parseInt(value || '0');
      const invalidProposal: boolean = proposedKeysCount > this.availableAccessKeysCount || proposedKeysCount < 0;

      this.additionalKeysCountInputTarget.classList.toggle('error', invalidProposal);
      this.submitButtonTarget.disabled = invalidProposal;
      if(invalidProposal) this.displayError([ErrorMessages.InvalidNumberOfKeys]);
    }
  }

  public submit(event: Event): void {
    event.preventDefault();
    this.hideError()
    this.consumeEndpoint();
  }

  private consumeEndpoint(): void {
    axios.post(
      this.formTarget.action,
      this.buildPayload(),
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }
    )
    .then(_r => { this.invokeRefreshPage() })
    .catch(error => { this.displayError([ErrorMessages.UnexpectedError]) })
  }

  private buildPayload(): object {
    return {
      additional_keys_count: this.additionalKeysCountInputTarget.value
    }
  }

  private invokeRefreshPage(): void {
    window.location.reload();
  }

  private displayError(errors: string[]): void {
    this.manipulateErrorContainer(errors);
  }

  private hideError(): void {
    this.manipulateErrorContainer([]);
  }

  private composeError(errors: string[]): string {
    if (errors.length === 0) return '';
    return errors.map(error => `<li>${error}</li>`).join('');
  }

  private manipulateErrorContainer(errors: string[]): void {
    const errorsComposed = this.composeError(errors);
    this.errorContainerTarget.innerHTML = errorsComposed;
    this.errorContainerTarget.classList.toggle('hide', errorsComposed === '');
  }
}
