import { Controller } from 'stimulus'

export default class extends Controller {
  public toggleAllowAbstain(event: Event): void {
    const dataId = (event.currentTarget as HTMLElement).dataset.id;
    const allowAbstain = document.querySelector(`#question_${dataId}_question_allow_abstain`);
    const allowComments = document.querySelector(`#question_${dataId}_question_allow_comments`);

    if(allowAbstain.checked) {
      allowComments.classList.add('hide');
    } else {
      allowComments.classList.remove('hide');
    }
  }
}
