import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['examples', 'formElement'];

  declare examplesTarget: HTMLElement;

  declare formElementTarget: HTMLFormElement;

  declare showExamplesIsOn: Boolean;

  // The submission is handled by another file
  // See app/assets/javascripts/controllers/elections.ballot_design.coffee:98
  initialize(): void {
    this.examplesTarget.addEventListener('click', this.clickHandler);
    this.originalFormAction = this.formElementTarget.getAttribute('action');
    this.alternatFormAction =
      this.alternatFormAction ||
      this.formElementTarget.dataset.createQuestionRoute;

    this.form = this.element.querySelector('form');
    if (this.blankQuestionOption) {
      this.blankQuestionListener = this.blankQuestionOption.addEventListener(
        'change',
        this.updateFormAction
      );
    }
  }

  handleFormChange(event) {
    if (event.target.tagName !== 'INPUT') return;

    const targetForAttribute = event.target.getAttribute('for');
    if (targetForAttribute === null) {
      return;
    }

    if (targetForAttribute !== 'question_template_type_blank') {
      this.formElementTarget.action = this.originalFormAction;
      this.formElementTarget.method = 'GET';
      return;
    }

    this.formElementTarget.action = this.alternatFormAction;
    this.formElementTarget.method = 'POST';
  }

  clickHandler(event) {
    const {
      target,
      target: { id },
    } = event;

    const identifier = id.split('-')[1];

    const exampleElement = document.querySelector(`#example-${identifier}`);

    if (!exampleElement) return;

    event.preventDefault();
    event.stopPropagation();

    if (exampleElement.hasAttribute('hidden')) {
      target.innerText = 'Hide sample';
      exampleElement.removeAttribute('hidden');
    } else {
      target.innerText = 'Sample';
      exampleElement.setAttribute('hidden', 'hidden');
    }
  }
}
