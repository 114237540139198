import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'mobileMenuOverlay',
    'secondaryNav',
    'secondaryNavButton',
    'headerNav',
    'mobileMenuIcon',
  ];

  declare mobileMenuOverlayTarget: HTMLElement;
  declare secondaryNavTarget: HTMLElement;
  declare secondaryNavButtonTarget: HTMLElement;
  declare headerNavTarget: HTMLElement;
  declare mobileMenuIconTarget: HTMLElement;

  initialize(): void {}

  public toogleSecondayNav(): void {
    this.secondaryNavTarget.classList.toggle('visible');
    this.secondaryNavButtonTarget.classList.toggle('open');
  }

  public tooglewMobileMenu(): void {
    this.headerNavTarget.classList.toggle('visible');
    this.mobileMenuIconTarget.classList.toggle('open');
    this.hideOverflow();
  }

  private hideOverflow(): void {
    const body = document.querySelector('body')!;

    body.classList.toggle('hide-overflow');
  }
}
