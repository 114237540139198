import { Controller } from "stimulus"
import { joinUnlessMissingOrSame } from "utils/string_joins"


export default class extends Controller {
  static targets = ['input', 'label']

  connect() {
    const callback = this.#syncLabelCallback()

    // Call once to set initial value
    callback()

    this.inputTarget.addEventListener('input', callback)
  }

  #syncLabelCallback() {
    const that = this
    return () => {
      that.labelTarget.innerText = joinUnlessMissingOrSame(
        that.#prefix(),
        that.inputTarget.value
      )
    }
  }

  #prefix() {
      return this.data.get('prefix')
  }
}
