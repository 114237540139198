import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dateSelect', 'frequencySelect', 'submitButton']

  declare dateSelectTarget: HTMLInputElement;
  declare frequencySelectTarget: HTMLInputElement;
  declare submitButtonTarget: HTMLElement;

  public enableSubmit(_event) {
    this.submitButtonTarget.disabled = !this.surveyCompleted();
  }

  private surveyCompleted() {
    return this.dateSelectTarget.value.length > 0 && this.frequencySelectTarget.value.length > 0;
  }
}
