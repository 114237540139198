import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['answerSelect']

  declare answerSelectTarget: HTMLSelectElement;

  public answerInputChange(event: Event): void {
    const warningSpan = document.querySelector('.warning-message');
    const answerText = this.answerText();
    const questionText = this.questionText();
    const submitButton = document.querySelector('#remove-choice-button');

    if(warningSpan) {
      if(answerText.length === 0) {
        warningSpan.innerHTML = '';
        submitButton.disabled = true;
      }
      else {
        warningSpan.innerHTML = `
          <span>
            <strong>${answerText}</strong> will be <strong>removed</strong> as a choice from <strong>${questionText}</strong>.
            <br> This removal is final and cannot be undone.
          </span>`;

        submitButton.disabled = false;
      }
    }
  }

  private questionText(): string {
    return this.answerSelectTarget.dataset.questionText || '';
  }

  private answerText(): string {
    const answerSelected = this.answerSelectTarget.selectedIndex;

    if (answerSelected === 0) {
      return '';
    }

    return this.answerSelectTarget.options[answerSelected].textContent || '';
  }
}
