import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['orgKeyField', 'orgKeyFieldContainer']

  initialize() {
    if (window.location.hash === '#edit_org_key') {
      this.orgKeyFieldTarget.focus()
      this.orgKeyFieldContainerTarget.classList.add('flash_highlight')

      // replaceState to remove the hash from the url
      window.history.replaceState(null, null, ' ')

      // set cursor to end of input
      const { length } = this.orgKeyFieldTarget.value
      this.orgKeyFieldTarget.setSelectionRange(length, length)
    }
  }
}
