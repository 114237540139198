import { BaseController } from '../../../base_controller';

export default class extends BaseController {
  static targets = ['submitButton', 'editButton'];

  declare submitButtonTarget: HTMLButtonElement;
  declare editButtonTarget: HTMLButtonElement;

  public submit(): void {
    this.submitButtonTarget.click();
  }

  public edit(): void {
    this.editButtonTarget.click();
  }
}
