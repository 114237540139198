import { ActionMenuModel as ActionMenuModelBase } from '../../../action_menu_component/action_menu_component_model';

export class ActionMenuModel extends ActionMenuModelBase {

  private addUsersAnchor: HTMLAnchorElement;

  constructor(public element: HTMLElement) {
    super(element, 'span#actions-menu-footer')
    this.addUsersAnchor = element.querySelector('a#inviteUsersAction');
  }

  public updateInviteUsersLink(organizationIds: number[]): void {
    if (organizationIds.length === 0) {
      this.addUsersAnchor.href = `${this.parseInvitationLinkURL()}`;
    } else {
      this.addUsersAnchor.href = `${this.parseInvitationLinkURL()}?${this.organizationIdsAsParams(organizationIds)}`;
    }
  }

  private parseInvitationLinkURL(): string {
    return this.addUsersAnchor.href.split('?')[0];
  }

  private organizationIdsAsParams(organizationIds: number[]): string {
    return organizationIds.map(id => `organization_ids[]=${id.toString()}`).join('&');
  }
}
