import { Controller } from 'stimulus'

export default class extends Controller {
  fireEvent(event): void {
    let type = `${this.identifier}:voterViewResultsType`
    let fromModal = (event.target.dataset.fromModal ?? 'false') === 'true'

    event.preventDefault()
    this.element.dispatchEvent(
      new CustomEvent(
        type,
        {
          detail: {
            voterViewResultsType: event.target.value,
            fromModal: fromModal
          },
          bubbles: true
        }
      )
    )
  }
}