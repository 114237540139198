import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['organizationLogoSection', 'logoPreview'];

  declare organizationLogoSectionTarget: HTMLElement;
  declare logoPreviewTarget: HTMLElement;

  public toggleOrganizationLogo(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.organizationLogoSectionTarget.classList.toggle('hide', !isChecked);
  }

  public updateLogoPreview(event: Event): void {
    const input = event.target;
    const preview = this.logoPreviewTarget;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        preview.innerHTML = `<img src="${e.target.result}" alt="Logo Preview" width="100" height="100" />`;
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
}
