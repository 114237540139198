import BaseQuestion from './base_question'

export default class CumulativeQuestion extends BaseQuestion {
  constructor(id, min, max, availableVotes, allowIncomplete) {
    super(id, min, max)
    this.availableVotes = availableVotes
    this.allowIncomplete = allowIncomplete
  }
  get answerListSelector() {
    return `#question_${this.id} input[type=number]`
  }

  // eslint-disable-next-line class-methods-use-this
  responseToAnswer(element) {
    return element.value
  }

  get answered() {
    return this.answers.map(answer => parseInt(answer.answered)).reduce((acc, y) => acc + y, 0)
  }

  get choices() {
    return this.answers.map(answer => parseInt(answer.answered)).filter(answer => answer !== 0).length
  }

  outOfRange() {
    const outOfMinMax = (this.min > this.choices || this.max < this.choices)
    const outOfVotes = this.answered > this.availableVotes
    const notReachExactlyVotes = !this.allowIncomplete && this.answered < this.availableVotes

    return outOfMinMax || outOfVotes || notReachExactlyVotes
  }
}
