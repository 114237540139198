import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['currentTime', 'timerEndDate', 'countdownTimer', 'countdownTimerComponent']

  declare currentTimeTarget: HTMLInputElement;
  declare timerEndDateTarget: HTMLInputElement;
  declare countdownTimerTarget: HTMLInputElement;
  declare countdownTimerComponentTarget: HTMLInputElement;

  connect(): void {
    let currentDate = new Date(this.currentTimeTarget.value);
    let endDate = new Date(this.timerEndDateTarget.value);
    let counter: number = (endDate.getTime()/1000 - currentDate.getTime()/1000);

    const timer = setInterval(() => {
      let minutes: number = parseInt(counter / 60, 10);
      let seconds: number = parseInt(counter % 60, 10);

      counter--;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.countdownTimerTarget.textContent = minutes + ':' + seconds;

      if (counter < 0) {
        clearInterval(timer);
        this.countdownTimerTarget.textContent = '00:00';
        this.blinkElement(this.countdownTimerTarget, this.countdownTimerComponentTarget)
      }
    }, 1000);
  }

  private blinkElement(element: HTMLInputElement, component: HTMLInputElement): void {
    let count: number = 0;

    var x = setInterval(function () {
      element.style.visibility = (element.style.visibility == 'hidden' ? '' : 'hidden');

      if (count++ === 10) {
        clearInterval(x);
        component.style.visibility = 'hidden';
      }
    }, 1000);
  }
}
