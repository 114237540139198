import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'saveButton', 'container']

  initialize() {
    this.originalFormTarget = this.formTarget.action
  }

  changeSelection(event) {
    this.formTarget.action = event.target.dataset.path
  }

  saveButtonClick(event) {
    event.preventDefault()
    if (this.formTarget.action === this.originalFormTarget) {
      $.modal.close()
    } else {
      $(event.target).addClass('disabled').text('Saving ...')
      Rails.fire(this.formTarget, 'submit')
    }
  }

  success() {
    const container = this.containerTarget
    container.parentElement.removeChild(container)
    $.modal.close()
  }
}
