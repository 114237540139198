export const letterToIndex = (letters: string): number => {
  let result = 0;
  const alphabetSize = 26;
  const asciiOffset = 'A'.charCodeAt(0);

  for (let i = 0; i < letters.length; i++) {
    const currentChar = letters.charAt(i);
    const currentCharValue = currentChar.charCodeAt(0) - asciiOffset + 1;
    result = result * alphabetSize + currentCharValue;
  }

  return result;
};
