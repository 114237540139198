export default class Answer {
  constructor(id, answered) {
    this.id = this.extractNumber(id)
    this.answered = answered
  }

  extractNumber(id) {
    const match = id.match(/\d+$/)
    return match ? parseInt(match[0], 10) : null
  }
}
