import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  initialize(): void {
    // Prior to loading the modal, a spinner is shown for better UX
    // Once the component is loaded, spinner needs to stop to allow modal interaction
    NProgress.done()
  }

  // The logic for this.activateScript() was implemented due to an existing bug with Rails 6
  //    where <script> tags are NOT evaluated when placed within
  //    turbo_frame_tags (i.e. putting <%= javascript_include_tag :date_picker %> into this component doesn't work)
  // Important: Any components that have js dependencies and are loaded into the turbo_frame_tag of this component (page_component)
  //    must have their javascript_include_tag declared above the turbo_frame_tag
  // Reference:
  // - https://github.com/hotwired/turbo/pull/192
  // - Workaround: https://gist.github.com/xtr3me/cf99f6673ebcca83f111abd41e9676bb#file-turbo_issue_186_workaround_controller-js
  connect(): void {
    [...this.element.children].map(el => this.activateScript(el))
  }
  
  disconnect(): void {
    [...this.element.children].map(el => this.removeScript(el));
  }

  removeScript(element): void {
    if (element.nodeName === 'SCRIPT') {
      element.parentElement?.removeChild(element);
    }
  }

  activateScript(element): void {
    if (element.nodeName === 'SCRIPT') {
      const createdScriptElement = document.createElement("script");
      createdScriptElement.textContent = element.textContent;
      createdScriptElement.async = false;
      this.copyElementAttributes(createdScriptElement, element);
      this.replaceElementWithElement(element, createdScriptElement);
    }
  }

  replaceElementWithElement(fromElement, toElement): void {
    const parentElement = fromElement.parentElement
    if (parentElement) {
      return parentElement.replaceChild(toElement, fromElement)
    }
  }

  copyElementAttributes(destinationElement, sourceElement): void {
    for (const { name, value } of [ ...sourceElement.attributes ]) {
      destinationElement.setAttribute(name, value)
    }
  }
}
