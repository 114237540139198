import axios from 'axios';

export const requestHTML = async (url: string, params = {}): Promise<string> => {
  const res = await axios.get(url, {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
      'X-CSRF-Token': getToken(),
    },
    params,
  });

  const html = res.data;

  return html;
}

export const getToken = (): string => {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content');

  return token || '';
}
