import axios from 'axios';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'errorContainer',
    'userEmail',
    'userFirstName',
    'userLastName',
    'organizationIds'
  ]

  declare readonly formTarget: HTMLFormElement;
  declare readonly userEmailTarget: HTMLInputElement
  declare readonly userFirstNameTarget: HTMLInputElement
  declare readonly userLastNameTarget: HTMLInputElement
  declare readonly organizationIdsTargets: HTMLInputElement[]
  declare errorContainerTarget: HTMLElement;

  private selectedRole: string;
  private organizationIds: string[];

  public connect(): void {
    this.organizationIds = this.organizationIdsTargets.map(target => target.value);
  }

  public selectRole(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedRole = target.value;
  }

  public submit(event: Event): void {
    event.preventDefault();
    this.hideError();
    this.consumeEndpoint();
  }

  private consumeEndpoint(): void {
    axios.post(
      this.formTarget.action,
      this.buildPayload(),
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }
    )
    .then(_r => { this.invokeRefreshPage() })
    .catch(error => { this.displayError(error.response.data) })
  }

  private buildPayload(): object {
    return {
      email: this.userEmailTarget.value,
      first_name: this.userFirstNameTarget.value,
      last_name: this.userLastNameTarget.value,
      role: this.selectedRole,
      organization_ids: this.organizationIds
    }
  }

  private invokeRefreshPage(): void {
    window.location.reload()
  }

  private displayError(errors: string[]): void {
    this.manipulateErrorContainer(errors);
  }

  private hideError(): void {
    this.manipulateErrorContainer([]);
  }

  private composeError(errors: string[]): string {
    if (errors.length === 0) return '';
    return errors.map(error => `<li>${error}</li>`).join('');
  }

  private manipulateErrorContainer(errors: string[]): void {
    const errorsComposed = this.composeError(errors);
    this.errorContainerTarget.innerHTML = errorsComposed;
    this.errorContainerTarget.classList.toggle('hide', errorsComposed === '');
  }


}
