import { BaseController } from '../../../base_controller';

export default class extends BaseController {
  static targets = [
    'input',
    'messageContainer',
    'searchButton',
    'accessBallotButton',
    'exitButton',
  ];

  static values = {
    messageIsError: Boolean,
    invitationPresent: Boolean,
  };

  declare inputTarget: HTMLInputElement;
  declare messageContainerTarget: HTMLElement;
  declare searchButtonTarget: HTMLButtonElement;
  declare accessBallotButtonTarget: HTMLLinkElement;
  declare exitButtonTarget: HTMLLinkElement;
  declare messageIsErrorValue: Boolean;
  declare invitationPresentValue: Boolean;
  declare inputHasChanged: Boolean;

  initialize() {
    this.updateFocus();
  }

  public exit(event: Event): void {
    event.preventDefault();
    this.exitButtonTarget.click();
  }

  public clearInput(event: Event) {
    event.preventDefault();
    this.clearInputTarget();
  }

  public inputChange(event: Event): void {
    this.inputHasChanged = true;
    this.disableAccessBallot();
    this.hideMessage();
    this.updateSubmitStatus(event.target.value);
  }

  public handleEnter(event: Event) {
    event.preventDefault();

    if (this.shouldClearInput()) {
      this.clearInputTarget();
    } else {
      this.submit();
    }
  }

  // Private methods

  private shouldClearInput() {
    return !this.inputHasChanged && this.messageIsErrorValue;
  }

  private submit(): void {
    this.searchButtonTarget.click();
  }

  private clearInputTarget() {
    this.inputTarget.value = '';
    this.hideMessage();
  }

  private updateSubmitStatus(targetValue) {
    if (targetValue.length > 0) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  private updateFocus() {
    if (this.invitationPresentValue) {
      this.accessBallotButtonTarget.focus();
    } else {
      this.inputTarget.focus();
    }
  }

  private enableSubmit(): void {
    this.searchButtonTarget.disabled = false;
  }

  private disableSubmit(): void {
    this.searchButtonTarget.disabled = true;
  }

  private disableAccessBallot(): void {
    this.accessBallotButtonTarget.classList.add('disabled');
  }

  private hideMessage(): void {
    this.messageContainerTarget.classList.add('hide');
  }
}
