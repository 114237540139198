import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  refresh(event) {
    let [_data, _status, xhr] = event.detail;
    document.querySelector('.voter_list_table_component').innerHTML = xhr.response;
  }

  update(event) {
    EB.Elections.VoterListController.disableAdminProceedToReview('validate_voter_list_message')
    let voterAttributeValue = event.target.getAttribute('data-voter-attribute-value');
    let voterAttributeUpdatedValue =  event.target.innerText;
    if (voterAttributeValue == voterAttributeUpdatedValue) {
      event.preventDefault();
      return;
    }

    let voterParams = JSON.parse(event.target.getAttribute('data-voter-params'));
    let voterAttributeName = event.target.getAttribute('data-voter-attribute-name');
    voterParams[voterAttributeName] = voterAttributeUpdatedValue;
    let voterId = voterParams['id'];

    $.ajax({
      url: `voter_information_lines/${voterId}`,
      type: 'PATCH',
      dataType: 'SCRIPT',
      data: voterParams
    });
  }
}
