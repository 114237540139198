import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['organizations'];

  connect() {
    const organizations = $(this.organizationsTarget);
    this.organizationsToSelect2(organizations);
  }

  organizationsToSelect2(e) {
    e.select2().on('select2:select', this.organizationSelected.bind(this));
  }

  organizationSelected() {
    const url = window.location.href;
    Turbo.visit(
      `/admin/vote_boxes/render_users?organization_id=${this.organizationsTarget.value}`,
      { action: 'replace' }
    );
    history.pushState(null, null, url);
  }
}
