import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['delaySelect', 'kioskLink', 'launchButton']

  handleDelayChange() {
    this.kioskLinkTarget.innerText = this.kioskUrl()
  }

  launch() {
    window.location.assign(this.kioskUrl())
  }

  kioskUrl() {
    const baseURL = new URL(this.launchButtonTarget.getAttribute('data-kiosk-base-url'))
    const delay = this.delaySelectTarget.value
    if (delay) {
      baseURL.searchParams.append('delay', this.delaySelectTarget.value)
    }
    return baseURL.toString()
  }
}
