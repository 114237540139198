import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.element.addEventListener(
      'input', this.checkCorrectNumberOfChars.bind(this)
    )
    this.maxLength = this.element.maxLength;
    this.helpTextShown = false
    this.helpTextElement = this.element.parentElement.querySelector('.help_text') || this.makeHelpTextElement();
  }

  makeHelpTextElement() {
    const helpText = document.createElement('span')
    helpText.classList.add('help_text')
    this.element.after(helpText)

    return helpText;
  }

  checkCorrectNumberOfChars(event) {
    if (event.target.value.length >= this.maxLength) {
      this.element.classList.add('max-length-reached')
      if (!this.helpTextShown) this.showCharacterLimitHelpText()
    } else {
      this.element.classList.remove('max-length-reached')
    }
  }

  showCharacterLimitHelpText() {
    this.helpTextShown = true
    this.helpTextElement.innerHTML = `Maximum length ${this.maxLength} characters.<br /> ${this.helpTextElement.textContent} `
  }
}
