import consumer from "./consumer"
import NProgress from 'nprogress'

consumer.subscriptions.create("LoadingIndicatorChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if(data.status === 'start') {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }
});
