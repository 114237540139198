import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['editIcon', 'textField']

  declare editIcon: HTMLElement;
  declare textFieldTarget: HTMLInputElement;

  public handleEditIcon(): void {
    this.hideEditIcon();
    this.textFieldTarget.select();
  }

  public showEditIcon(): void {
    this.editIconTarget.style.visibility = 'visible';
  }

  public hideEditIcon(): void {
    this.editIconTarget.style.visibility = 'hidden';
  }
}
