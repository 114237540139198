import { Controller } from 'stimulus';
import { EVENTS as RESULTS_EVENTS } from '../prior_question_results_component/prior_question_results_component_controller';

const LIMIT_INCREASE = 5;

export default class extends Controller {
  static targets = [
    'clearButton',
    'searchbutton',
    'keywordInput',
    'formLimitValue',
  ];

  declare clearButtonTarget: HTMLButtonElement;
  declare searchbuttonTarget: HTMLButtonElement;
  declare formLimitValueTarget: HTMLInputElement;
  declare keywordInputTarget: HTMLInputElement;

  connect(): void {
    this.addListeners();
    this.populateResults();
  }

  public submit(): void {
    this.searchbuttonTarget.click();
  }

  public keywordInputChange(): void {
    this.resetFormLimit();
  }

  public clearInput() {
    this.clearKeywordInput();
    this.populateResults();
  }

  private clearKeywordInput() {
    this.keywordInputTarget.value = '';
  }

  private populateResults() {
    this.submit();
  }

  private loadAdditionalResults() {
    this.incresseFormLimit();
    this.submit();
  }

  private incresseFormLimit() {
    const current = Number(this.formLimitValueTarget.value);
    const updatedLimit = current + LIMIT_INCREASE;
    this.formLimitValueTarget.value = String(updatedLimit);
  }

  private resetFormLimit() {
    this.formLimitValueTarget.value = String(LIMIT_INCREASE);
  }

  private addListeners() {
    document.addEventListener(RESULTS_EVENTS.LOAD_ADDITIONAL_RESULTS, () => {
      this.loadAdditionalResults();
    });
  }
}
