import { Controller } from 'stimulus'
import { Elections } from 'electionbuddy'
import NProgress from 'nprogress'

export default class extends Controller {
  connect() {
    if (Elections.Results.pageRequiresDragAndDropFeature(document.body)) {
      Elections.Results.initWriteInDrop(this.element)
    }
  }

  startSpinner() {
    NProgress.start()
  }

  stopSpinner() {
    NProgress.done()
  }
}
