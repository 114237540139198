import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['selectInput', 'container', 'checkbox']

  initialize() {
    const dataSet = this.containerTarget.dataset
    const { questionId } = dataSet

    window.EB.Ballots.hiddenWriteFromSelectList = window.EB.Ballots.hiddenWriteFromSelectList || {}
    const list = window.EB.Ballots.hiddenWriteFromSelectList

    this.form = this.selectInputTarget.form.addEventListener('change', this.handleFormChange.bind(this))
    this.$selectInput = $(this.selectInputTarget)
    this.answerLimit = dataSet.answerLimit
    this.allowShuffle = dataSet.allowShuffle
    this.searchSeed = dataSet.searchSeed

    if (this.containerTarget.classList.contains('js_hidden_writein')) {
      list[`question_${questionId}`] = list[`question_${questionId}`] || []
      list[`question_${questionId}`].push(this.setupInput.bind(this))
    } else {
      this.setupInput()
    }
  }

  setupInput() {
    const listsToFetchCandidatesFrom = {
      candidate_names: this.setupSelectInput.bind(this),
      sample_candidate_names: this.setupSampleSelectInput.bind(this),
    }
    const list = this.containerTarget.dataset.listToUse
    listsToFetchCandidatesFrom[list](this.selectInputTarget)
  }

  setupSelectInput() {
    this.$selectInput.select2({
      allowClear: true,
      ajax: {
        url: this.containerTarget.dataset.url,
        dataType: 'json',
        delay: 250,
        data: (params) => ({
          term: params.term,
          page: params.page || 1,
          answer_limit: this.answerLimit,
          allow_shuffle: this.allowShuffle,
          search_seed: this.searchSeed,
          per_page: 10,
        }),
        processResults: (data, params) => {
          data.results.map(i => (i.id = i.text))
          params.page = params.page || 1
          params.per_page = params.per_page || 10

          return {
            results: data.results,
            pagination: {
              more: (params.page * params.per_page) < data.meta.total
            }
          }
        }
      }
    }).on('select2:select', this.handleSelectionMade.bind(this))
  }

  handleSelectionMade(event) {
    if(!this.containerTarget.querySelector('td:last-child').textContent.trim()) return; //return if checkbox is not inside the DOM
    if (event.target.value && !this.checkboxTarget.checked) this.checkboxTarget.click()
  }

  handleFormChange(event) {
    const target = event.target
    if (
      target.name == this.checkboxTarget.name &&
      target.getAttribute('type') == 'radio' &&
      target != this.checkboxTarget
    ) this.$selectInput.val(null).trigger('change')
  }

  setupSampleSelectInput() {
    const data = {
      answer_limit: this.answerLimit,
      allow_shuffle: this.allowShuffle,
    }

    if (this.allowShuffle) data.search_seed = this.searchSeed

    Rails.ajax({
      url: this.containerTarget.dataset.url,
      type: 'get',
      data: new URLSearchParams(Object.keys(data).map(key=>[key,data[key]])),
      success: this.fetchSampleDataSuccess.bind(this)
    });
  }

  changes(event) {
    if (!event.target.checked) this.$selectInput.val(null).trigger('change')
  }

  fetchSampleDataSuccess(data) {
    data.results.map(i => i.id = i.text)
    this.$selectInput.select2({
      allowClear: true,
      data: data.results
    }).on('select2:select', this.handleSelectionMade.bind(this))
  }
}
