import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['activationCheckbox', 'settingsSection'];

  declare activationCheckboxTarget: HTMLInputElement;
  declare settingsSectionTarget: HTMLElement;

  public toggleSetting(_event: any): void {
    if (this.activationCheckboxTarget.checked) {
      this.settingsSectionTarget.classList.add("active");
    } else {
      this.settingsSectionTarget.classList.remove("active");
    }
  }
}
