import { Controller } from "stimulus"

export default class extends Controller {
  static targets: string[] = ['form']

  declare readonly formTarget: HTMLFormElement

  submit(event): void {
    this.formTarget.requestSubmit()
  }
}
