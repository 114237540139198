import { Controller } from 'stimulus';
import Quill from 'quill';

export default class extends Controller {
  static targets = ['container', 'variablesSelect'];

  declare containerTarget: HTMLDivElement;
  declare variablesSelectTarget: HTMLSelectElement;

  initialize() {
    window.EB.RichTextFields = window.EB.RichTextFields || {};
    window.EB.RichTextFields[this.containerTarget.dataset.id] = this;
  }

  public childrenInitCallback(richTextEditorInstance): void {
    const selectOptions = $(this.variablesSelectTarget).select2({
      placeholder: 'Variables',
      value: null
    })

    selectOptions.on('select2:select', (event) => {
      this.insertVariableIntoTemplate(richTextEditorInstance.quill, selectOptions, event)(event);
    });

    selectOptions.on('change', (event) => {
      event.stopPropagation();
    })

    selectOptions.val(null).trigger('change');

    const select2Container = this.variablesSelectTarget.nextElementSibling;
    const toolbar = richTextEditorInstance.quill.container.previousElementSibling;

    if (select2Container) {
      toolbar.append(select2Container);
    }
  }

  private insertVariableIntoTemplate(quill, selectOptions, event) : void{
    return function(value) {
      const text = event.target.value;
      const range = quill.getSelection(true);

      if (!text) return

      quill.insertText(range.index, text, Quill.sources.USER)
      quill.setSelection(range.index + text.length, Quill.sources.SILENT)
      selectOptions.val(null).trigger('change')
    }
  }
}

