import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  filter(event) {
    let dataset = event.currentTarget.dataset;

    if (dataset.tally === "false") {
      event.preventDefault();

      let targetHref = event.currentTarget.href;
      let electionId = dataset.election;

      this._redirectIfAllowed(electionId, targetHref);
    }
  }

  _redirectIfAllowed(electionId, targetHref) {
    Rails.ajax({
      type: "get",
      url: `/elections/${electionId}/secure_tally_results`,
      success: (data) => {
        if (data.allow_redirect) {
          window.location.replace(targetHref);
        } else if(data.low_security_with_proxy) {
          this._buildProxyModal(targetHref, data.low_security_with_proxy);
        } else {
          this._buildModal(data.votes_remaining, targetHref, data.proxy_opened);
        }
      }
    });
  }

  _buildModal(votesRemaining, targetHref, proxyOpened) {
    $("#result-locker-modal").remove();

    let modalHtml = this._createModalStructure(votesRemaining, targetHref, proxyOpened);
    $("body").append(modalHtml);
    $("#result-locker-modal").modal();
  }

  _createModalStructure(votesRemaining, targetHref, proxyOpened) {
    const proxyMessage = this._createProxyMessage(proxyOpened);

    return `
    <div id="result-locker-modal" class="modal">
      <h3 class="template_error">Warning</h3>
      <p>
        ${votesRemaining} voters have not voted. These voters may submit paper ballots.
      </p>

      <p class="template_error">
        If results are viewed, no more paper ballots can be recorded by administrators
      </p>
      ${proxyMessage}
      <div class="actions">
        <a class="btn btn--orange" rel="modal:close">Cancel</a>
        <a class="btn btn--orange" href="${targetHref}">Close Paper Ballot Entry and View Results</a>
      </div>
    </div>
    `;
  }

  _buildProxyModal(targetHref, proxyOpened) {
    $("#result-locker-proxy-modal").remove();

    let modalHtml = this._createProxyModalStructure(targetHref, proxyOpened);
    $("body").append(modalHtml);
    $("#result-locker-proxy-modal").modal();
  }

  _createProxyModalStructure(targetHref, proxyOpened) {
    const proxyMessage = this._createProxyMessage(proxyOpened);

    return `
    <div id="result-locker-proxy-modal" class="modal">
      <h3 class="template_error">Warning</h3>
      ${proxyMessage}
      <div class="actions">
      <a class="btn btn--orange" rel="modal:close">Cancel</a>
      <a class="btn btn--orange" href="${targetHref}">Close Proxy Entry and View Results</a>
    </div>
    `;
  }

  _createProxyMessage(proxyOpened) {
    if (proxyOpened) {
        return `
            <p class="template_error">
                This vote has proxy vote active. If the results are viewed, the administrator won't be able to record the proxy vote.
            </p>
        `;
    } else {
        return '';
    }
  }
}
