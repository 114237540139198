import consumer from "./consumer"

function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

consumer.subscriptions.create("DownloadFileChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    let url = data.url

    if (!url) {
      return
    }

    if(isFirefox()) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
  }
});
