import BaseQuestion from './base_question'

export default class PreferentialQuestion extends BaseQuestion {
  get answerListSelector() {
    return `#question_${this.id} select`
  }

  // eslint-disable-next-line class-methods-use-this
  responseToAnswer(element) {
    return element.value
  }
}
