import { Controller } from "stimulus"

export default class extends Controller {
  static targets: string[] = ['form', 'submitButton', 'previewButton', 'backButton', 'editVotingResultsSettingsButton', 'redirectUrl', 'preview']

  declare readonly formTarget: HTMLFormElement
  declare readonly submitButtonTarget: HTMLInputElement
  declare readonly previewButtonTarget: HTMLButtonElement
  declare readonly backButtonTarget: HTMLAnchorElement
  declare readonly editVotingResultsSettingsButtonTarget: HTMLAnchorElement
  declare readonly redirectUrlTarget: HTMLInputElement
  declare readonly previewTarget: HTMLInputElement

  connect(): void {
    EB.Elections.Notifications.init()
    this.initializeFields()
    this.formTarget.addEventListener('turbo:submit-end', event => this.handleSubmitResponse(event))
  }

  saveScheduleButton(event): void {
    event.preventDefault()

    this.disableActionButtons()

    this.formTarget.requestSubmit()
  }

  previewButton(event): void {
    event.preventDefault()

    this.disableActionButtons()

    this.previewTarget.value = 'true'

    this.formTarget.requestSubmit()
  }

  backButton(event): void {
    this.disableActionButtons()

    if (this.checkDirty()) {
      if (confirm('You have unsaved changes, do you want to save them?')) {
        event.preventDefault()
        this.formTarget.requestSubmit()
      }
    }
  }

  editVotingResultsSettingsButton(event): void {
    let redirect_url = event.target.getAttribute('href')

    this.disableActionButtons()
    
    event.preventDefault()

    if (this.checkDirty()) {
      if (confirm('You have unsaved changes, do you want to save them?')) {
        this.redirectUrlTarget.value = redirect_url
        this.formTarget.requestSubmit()
      } else {
        this.redirectBrowserTo(redirect_url)
      }
    } else {
      this.redirectBrowserTo(redirect_url)
    }
  }

  private initializeFields(): void {
    this.fields = [...this.formTarget.querySelectorAll('input, textarea')]
    this.fields.forEach(htmlField => htmlField.originalValue = htmlField.value)
  }

  private handleSubmitResponse(event): void {
    if (event.detail.success){
      let redirect_url = event.detail?.fetchResponse?.header('redirect_url')
      
      if (redirect_url) {
        this.redirectBrowserTo(redirect_url)
      }
    } else {
      this.enableActionButtons()
    }
  }
  
  private checkDirty(): boolean {
    return this.fields.some(htmlField => htmlField.originalValue != htmlField.value)
  }

  private disableActionButtons(): void {
    this.submitButtonTarget.classList.add('disabled')
    this.backButtonTarget.classList.add('disabled')
    this.editVotingResultsSettingsButtonTarget.classList.add('disabled')

    
    if (this.hasPreviewButtonTarget) {
      this.previewButtonTarget.classList.add('disabled')
    }
  }

  private enableActionButtons(): void {
    this.submitButtonTarget.classList.remove('disabled')
    this.backButtonTarget.classList.remove('disabled')
    this.editVotingResultsSettingsButtonTarget.classList.remove('disabled')

    if (this.hasPreviewButtonTarget) {
      this.previewButtonTarget.classList.remove('disabled')
    }
  }

  private redirectBrowserTo(url: string): void {
    window.top.location = url
  }
}
