import { Controller } from "stimulus";

interface Stripe {
  elements(options: object): Elements;
  createToken(card: Element, additionalData?: object): Promise<{ token: Token, error?: StripeError }>;
}

interface Elements {
  create(type: string, options?: object): Element;
}

interface Element {
  mount(element: HTMLElement): void;
  addEventListener(event: string, handler: (event: any) => void): void;
}

interface Token {
  id: string;
}

interface StripeError {
  message: string;
}

export default class extends Controller {
  static targets = ["cardErrors", "cardElement", "cardName", "form"];
  declare readonly cardErrorsTarget: HTMLElement;
  declare readonly cardElementTarget: HTMLElement;
  declare readonly cardNameTarget: HTMLInputElement;
  declare readonly formTarget: HTMLFormElement;

  private stripe: Stripe;
  private card: Element;

  connect(): void {
    const stripePublicKey: string = this.data.get('stripe-key-value') as string;
    this.stripe = Stripe(stripePublicKey);
    const elements: Elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic',
        },
      ],
      locale: 'auto'
    });
    const elementsStyles = {
      base: {
        fontFamily: 'Lato, sans-serif',
        fontSize: '18px',
      }
    };
    this.card = elements.create('card', { iconStyle: 'solid', style: elementsStyles });
    this.card.mount(this.cardElementTarget);

    this.card.addEventListener('change', (event: any) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message;
      } else {
        this.cardErrorsTarget.textContent = '';
      }
    });
  }

  submit(event: Event): void {
    event.preventDefault();
    const cardName = this.cardNameTarget.value;
    this.stripe.createToken(this.card, { name: cardName }).then((result: { token?: Token, error?: StripeError }) => {
      if (result.error) {
        this.cardErrorsTarget.textContent = result.error.message;
      } else if (result.token) {
        this.submitToken(result.token);
      }
    });
  }

  disconnect(): void {
    NProgress.done();
  }

  private submitToken(token: Token): void {
    NProgress.start();
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripe_token');
    hiddenInput.setAttribute('value', token.id);
    this.formTarget.appendChild(hiddenInput);
    this.formTarget.submit();
  }
}
