import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'accessKeyInput', 'passwordInput']

  connect() {
    const callback = this.#syncCallback()

    // Call it once to disable initially.
    callback()

    this.accessKeyInputTarget.addEventListener('input', callback)
    this.passwordInputTarget.addEventListener('input', callback)
  }

  #syncCallback() {
    const that = this
    return () => {
      that.submitTarget.disabled =
        that.accessKeyInputTarget.value.length === 0
        || that.passwordInputTarget.value.length === 0
    }
  }
}
