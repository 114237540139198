import { mapValues } from 'lodash';
import { BaseController } from '../../base_controller';

const UNIQUE_ID = 'ballots--key-overlay-component';

export const EVENTS = (() => {
  const events = {
    SHOW: 'show',
    HIDE: 'hide',
    SHAKE: 'shake',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = ['keyOverlay'];

  declare keyOverlayTarget: HTMLElement;

  static values = {
    identifier: String,
  };

  declare identifierValue: string;

  initialize(): void {
    this.addListeners();
  }

  private shake() {
    this.keyOverlayTarget.classList.add('error');

    setTimeout(() => {
      this.hide();
      this.keyOverlayTarget.classList.remove('error');
    }, 700);
  }

  private show(key) {
    this.keyOverlayTarget.innerHTML = key;
    this.keyOverlayTarget.classList.add('visible');
  }

  private hide() {
    this.keyOverlayTarget.innerHTML = '';
    this.keyOverlayTarget.classList.remove('visible');
  }

  private matchIdentifier({ identifier, key = null }, caller: string) {
    if (this.identifierValue !== identifier) return;

    this[caller](key);
  }

  // Listeners

  private addListeners() {
    document.addEventListener(EVENTS.SHOW, (event) => {
      this.matchIdentifier(event.detail, 'show');
    });

    document.addEventListener(EVENTS.HIDE, (event) => {
      this.matchIdentifier(event.detail, 'hide');
    });

    document.addEventListener(EVENTS.SHAKE, (event) => {
      this.matchIdentifier(event.detail, 'shake');
    });
  }
}
