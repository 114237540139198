import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['scheduledDateInput', 'scheduledDateInputContainer', 'scheduledOptionRadio']

  declare scheduledDateInputTarget: HTMLInputElement;
  declare scheduledDateInputContainerTarget: HTMLInputElement;
  declare scheduledOptionRadioTarget: HTMLInputElement;

  connect(): void {
    const element = document.querySelector('.election-notice-type--send-time-component');

    if (element !== null) {
      EB.Datepicker.initDatePickers(new Date());
    }
  }

  changes(event): void {
    if (event.currentTarget.value === 'scheduled') {
      this.scheduledDateInputContainerTarget.removeAttribute('hidden')
    } else {
      this.scheduledDateInputContainerTarget.setAttribute('hidden', 'hidden')
    }
  }
}
