import { Controller } from 'stimulus'

// We got an error report where the Stripe JS failed to load. The following code
// attempts to detect this scenario and to prompt the user to attempt to fix the
// issue themseleves by reloading the page.
class StripeCheck extends Controller {
  initialize() {
    StripeCheck.doCheck();
  }
}

// Make this function static so it gets created exactly once.
StripeCheck.doCheck = (function () {
  var alreadyStarted = false
  return function doCheck() {
      if (alreadyStarted) { return; }
      alreadyStarted = true
      setTimeout(
          function() {
              if (window.Stripe) { return; }

              var modalEl = $("<div class='modal'><p>Unable to connect to the payment server. Please reload the page and try again.</p><div class='actions'><a class='btn btn--orange' onClick='window.location.reload()'>Reload</a></div></div>")
              modalEl.appendTo('body').modal()
          },
          10 * 1000
      )
  }
})()

export default StripeCheck;
