export class ActionMenuModel {

  private footer: HTMLElement;

  constructor(public element: HTMLElement, private footerSelector: String) {
    this.footer = element.querySelector(footerSelector);
  }

  public changeVisibility(isVisible: boolean): void {
    const hideClass: string = 'hide';

    this.element.classList.toggle(hideClass, !isVisible);
  }

  public updateFooter(checkboxesSelected: number): void {
    this.footer.innerHTML = `${checkboxesSelected} selected`;
  }
}
