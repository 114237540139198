import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { requestHTML } from '../../utils/turbo';

export default class extends Controller {
  static targets: string[] = ['table', 'actionsMenu', 'tableRow'];

  static values = {
    actionsUrl: String,
    voteBoxId: Number,
  };

  declare readonly selectAllCheckboxTarget: HTMLInputElement;
  declare readonly tableTarget: Element;
  declare readonly tableRowTargets: Element[];

  declare actionsUrlValue: string;
  declare voteBoxIdValue: number;
  private tableRowCheckboxes: HTMLInputElement[];

  public connect() {
    this.defineTriggers();

    document.addEventListener('turbo:frame-load', () => {
      this.defineTriggers();
    });
  }

  private defineTriggers() {
    this.tableRowCheckboxes = this.tableRowTargets
      .map(
        (row) => row.querySelector('input[type="checkbox"]') as HTMLInputElement
      )
      .filter((checkbox) => checkbox !== null);
    this.assignActionToCheckboxes();
  }

  private assignActionToCheckboxes(): void {
    this.tableRowCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', (_event: Event) => {
        this.interactWithActionsMenu(checkbox);
      });
    });
  }

  private async interactWithActionsMenu(
    checkbox: HTMLInputElement
  ): Promise<void> {
    this.closeAll();
    const voteItemIds = this.voteItemIds();
    if (voteItemIds.length === 0) {
      return;
    }

    const turboStreamId = checkbox.getAttribute('turbo-stream-id')!;
    const actionsMenu = document.getElementById(turboStreamId)!;

    await this.getActions(voteItemIds, turboStreamId);

    actionsMenu.classList.toggle('hide', false);
  }

  private voteItemIds(): string[] {
    return this.tableRowCheckboxes.filter((el) => el.checked).map((el) => el.getAttribute('data-id'));
  }

  closeAll() {
    const actionsMenus = Array.from(
      document.querySelectorAll<HTMLInputElement>(
        '[id^=vote_item_actions_vote_]'
      )
    );

    actionsMenus.forEach((el) => el.classList.toggle('hide', true));
  }

  async getActions(voteItemIds, turboStreamId) {
    const params = {
      vote_item_ids: voteItemIds,
      turbo_stream_id: turboStreamId,
      vote_box_id: this.voteBoxIdValue,
    };
    const html = await requestHTML(this.actionsUrlValue, params);

    Turbo.renderStreamMessage(html);
  }
}
