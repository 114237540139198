import { Controller } from "stimulus";
import consumer from '../channels/consumer';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['attributes']

  connect() {
    this.channel = this.#createSubscription();
    this.handsOnTable = this.#buildHandsontable();
  }

  validate(event) {
    event.preventDefault();


    $.ajax('validate', {
      type: 'POST',
      dataType: 'json',
      data: {
        headers: this.handsOnTable.getSchema(),
        rows: this.handsOnTable.getData()
      }
    });
  }

  #createSubscription() {
    return consumer.subscriptions.create(
      {
        channel: "AppendVotersChannel",
        user_id: this.data.get('user-id')
      },
      {
        connected: this._cableConnected.bind(this),
        disconnected: this._cableDisconnected.bind(this),
        received: this._cableReceived.bind(this),
      }
    );
  }

  _cableConnected() {}

  _cableDisconnected() {}

  _cableReceived(data) {
    let type = data.type
    if(type == 'validation') {
      let validateButton = $('#validate')
      if(data.status == 'started') {
        validateButton.html('<i class="fas fa-check"></i>Validating rows, please wait...')
      } else if(data.status == 'done') {
        $('#voter_list_notices').empty()
        validateButton.html('<i class="fas fa-check"></i>Validate list')
        if(data.valid) {
          $('#download_errors_list').addClass('hide')
          $('#create').removeClass('hide')
          $('#voter_list_notices').html("<span class='inline_success'>Everything looks good!</span>")
        } else {
          $('#download_errors_list').removeClass('hide')
          $('#create').addClass('hide')
          $('#voter_list_notices').html("<span class='inline_error'>There are errors in your list that must be fixed before continuing. Select Download List to view the error and explanation in a spreadsheet. Then, fix the error, and verify by selecting Validate list again.</span>")
        }
      }
    } else {
      if(data.status = 'done') {
        window.location.pathname = data.redirect_path;
      }
    }
  }

  #buildHandsontable() {
    let columns = JSON.parse(this.attributesTarget.getAttribute('data-columns'));
    let remainingExtraKeys = Number(this.attributesTarget.getAttribute('data-remaining-extra-keys'));
    let options = {
      columns: columns,
      colHeaders: JSON.parse(this.attributesTarget.getAttribute('data-columns-headers')),
      rowHeaders: JSON.parse(this.attributesTarget.getAttribute('data-row-headers')),
      minSpareRows: remainingExtraKeys,
      maxRows: remainingExtraKeys,
      dataSchema: columns.reduce(function(memo, column) {
        memo[column.data] = null;
        return memo;
      }, {}),
      autoWrapRow: true,
      autoWrapCol: true,
      stretchH: 'all',
      pasteMode: 'overwrite',
      preventOverflow: 'horizontal',
      contextMenu: false
    };
    return new Handsontable($('#hands_on_table')[0], options);
  }
}

