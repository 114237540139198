import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = ['displayDiv', 'editDiv', 'h1Title', 'inputField'];

  private toggleDisplay() {
    this.displayDivTarget.classList.toggle('hide');
    this.editDivTarget.classList.toggle('hide');
  }

  private onSaveClick(e: Event) {
    this.toggleDisplay();
    const newTitle = this.inputFieldTarget.value;

    if (newTitle.length < 5 || newTitle.length > 60) {
      alert('Title must be between 5 and 60 characters');
      e.preventDefault()
      return;
    }
    this.h1TitleTarget.textContent = this.inputFieldTarget.value;
  }
}
