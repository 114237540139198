
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = [
    'submitButton',
    'phraseInput'
  ];

  declare submitButtonTarget: HTMLButtonElement;
  declare phraseInputTarget: HTMLInputElement;

  public connect(): void {
    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        window.location.reload();
      }
    })
  }

  public checkPhrase(): void {
    const correctPhrase = "transfer";
    const enteredPhrase = this.phraseInputTarget.value;

    if (enteredPhrase === correctPhrase) {
      this.submitButtonTarget.disabled = false;
    }
    else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
