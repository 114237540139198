import { BaseController } from '../../../base_controller';
import { mapValues } from 'lodash';

import { EVENTS as LIST_EVENTS } from '../result_list_component/result_list_component_controller';
import { EVENTS as ITEM_EVENTS } from '../vote_item_component/vote_item_component_controller';

const UNIQUE_ID = 'votes--dashboard--select-filters-component';

export const EVENTS = (() => {
  const events = {
    CHECK_ALL: 'check_all',
    UNCHECK_ALL: 'uncheck_all',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = [
    'button',
    'checkboxInput',
    'organizationsInput',
    'usersInput',
    'organizationsSelect',
  ];

  declare buttonTarget: HTMLButtonElement;
  declare checkboxInputTarget: HTMLInputElement;
  declare organizationsInputTarget: HTMLInputElement;
  declare usersInputTarget: HTMLInputElement;
  declare organizationsSelectTarget: HTMLInputElement;

  initialize(): void {
    this.initializeCustomSelect();
    this.addListeners();
  }

  public enableSubmit(event: Event): void {
    if (event.target.value.length > 0) {
      this.buttonTarget.disabled = false;
    }
  }

  public submit(): void {
    this.buttonTarget.disabled = false;
    this.buttonTarget.click();
  }

  // Custom select

  private initializeCustomSelect() {
    if (!this.isCustomSelectRendered()) return;

    const select = $(this.organizationsSelectTarget).select2();

    select.on('change', (e) => this.filterOrganization(e));
  }

  public filterOrganization(event: Event): void {
    const selection = event.target.value;

    const org = selection.match(/organization_(\d+)/);
    const user = selection.match(/user_(\d+)/);

    this.organizationsInputTarget.value = this.extractItem(org);
    this.usersInputTarget.value = this.extractItem(user);

    this.submit();
  }

  private isCustomSelectRendered() {
    return $('#organizations_select').length !== 0;
  }

  private extractItem(array, index = 1) {
    if (array) {
      return array.at(index);
    }

    return '';
  }

  // Checkbox

  public voteItemCheckAll(event) {
    if (event.target.checked) {
      this.emitCheckAll();
    } else {
      this.emitUncheckAll();
    }
  }

  private updateCheckboxDisabledStatus(value: boolean) {
    this.checkboxInputTarget.disabled = value;
  }

  private handleExternalToogle(event: CustomEvent) {
    const { value } = event.detail;

    this.checkboxInputTarget.checked = value;
  }

  private emitCheckAll() {
    this.dispatch(EVENTS.CHECK_ALL, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitUncheckAll() {
    this.dispatch(EVENTS.UNCHECK_ALL, {
      // @ts-ignore
      prefix: null,
    });
  }

  private addListeners() {
    document.addEventListener(LIST_EVENTS.CHECKBOX_TOOGLE, (event) => {
      this.handleExternalToogle(event as CustomEvent);
    });

    document.addEventListener(ITEM_EVENTS.DISABLED_STATUS, (event) => {
      this.updateCheckboxDisabledStatus(event.detail.value);
    });
  }
}
