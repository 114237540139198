import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "identifier" ]

  connect() {
    let identifier = `#${this.identifierTarget.id}`;
    $(identifier).multipleSelect({
      allSelected: true,
      formatAllSelected: () => $(identifier).data('all-options-selector-message'),
      formatSelectAll: () => $(identifier).data('toggle-selector-message'),
      filter: $(identifier).data('keyword-filter'),
      maxHeight: '400'
    });
  }
}
