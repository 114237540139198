import { Controller } from 'stimulus'
import {
  Chart,
  ArcElement,
  BarElement,
  BarController,
  PieController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Filler,
  Legend,
  Title,
} from 'chart.js';

Chart.register(
  ArcElement,
  BarElement,
  BarController,
  PieController,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Filler,
  Legend,
  Title,
);

import pattern from 'patternomaly'

const CHART_COLORS = '556270 4ecec4 c7f464 ff6b6b c44d58 ff8002 ff5c44 ff3f6f e63b95 b94ab2 7b58c2 0060c0'.split(' ').map(c => `#${c}`)
const CHART_PATTERNS_RANKED_BY_PREFERENCE = [
  'line-vertical',
  'zigzag',
  'zigzag-vertical',
  'diagonal',
  'diagonal-right-left',
  'line',
  'dot',
  'plus',
  'cross',
  'square',
  'dash',
  'cross-dash',
  'weave',
  'triangle',
  'triangle-inverted',
  'diamond',
  'box',
  'diamond-box',
  'dot-dash',
  'disc',
  'ring',
]

const PATTERNS_AND_COLORS = CHART_PATTERNS_RANKED_BY_PREFERENCE.reduce(
  (acc, patternName, index) => {
    const patternColor = CHART_COLORS[index % CHART_COLORS.length]
    acc.push(pattern.draw(patternName, patternColor))

    return acc
  }, []
)

const Y_AXIS_INTEGER_SCALE = {
  y: {
    beginAtZero: true,
    ticks: {
      stepSize: 1
    }
  }
}

export default class extends Controller {
  static targets = ['canvas']
  initialize() {
    Chart.defaults.font.family = 'Lato'
    Chart.defaults.font.size = 20
  }

  connect() {
    window.EB.charts = window.EB.charts || []
    window.EB.charts.push(this)
    this.chartNode = this.canvasTargets[0]
    this.chartData = JSON.parse(this.chartNode.dataset['chartData'])
    const chartData = this.chartData

    this.chartType = this.chartNode.dataset['chartType']

    if (!['bar', 'pie', 'horizontalBar'].includes(this.chartType)) {
      throw new Error("Unexpected chart type: " + this.chartType)
    }

    const chartOptions = this.constructor.generateChartOptions(this.chartType, chartData)
    
    this.chartArgs = {
      type: this.chartType != 'pie' ? 'bar' : 'pie',
      data: chartData,
      options: chartOptions
    }
    this.setBackground(this.chartNode.dataset.chartPatterns === 'true')
    this.setScale()
    this.chart = new Chart(this.chartNode, this.chartArgs)
  }

  setBackground(patterned) {
    const POOL_TO_DRAW_FROM = patterned ? PATTERNS_AND_COLORS : [...CHART_COLORS, ...PATTERNS_AND_COLORS]

    if (this.chartData.is_scored_survey) {
      this.chartData.datasets.forEach((set) => {
        const background = set.data.map(() => POOL_TO_DRAW_FROM[(set.score - 1) % POOL_TO_DRAW_FROM.length])
        set.backgroundColor = background
      })
    } else {
      const colors = this.chartData.datasets[0].data.map(
        (_set, index) => POOL_TO_DRAW_FROM[index % POOL_TO_DRAW_FROM.length]
      )

      this.chartData.datasets.forEach((dataSet) => {
        dataSet.backgroundColor = colors
      })
    }
  }

  setScale() {
    if (this.chartType === 'horizontalBar') return

    this.chartArgs.options.scales = this.chartType === 'bar' ? Y_AXIS_INTEGER_SCALE : {}
  }

  redraw(change) {
    this.chart.reset()
    if (change.type && this.chartArgs.type !== 'horizontalBar') {
      this.chartType = change.type
      this.chartArgs.type = change.type
      this.setScale()
    }

    if (typeof change.patterns == 'boolean') {
      this.setBackground(change.patterns)
    }
    this.chart.update(this.chartArgs)
    this.chart.render()
  }

  static generateLegendOptions(chartType, chartData) {
    return {
      display: chartType !== 'bar' && !chartData.displays_average_scores,
      labels: {
        padding: chartData.labels?.length > 8 ? 15 : 10,
      }
    }
  }

  static generateChartOptions(chartType, chartData) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: this.generateLegendOptions(chartType, chartData),
      },
    }

    if (chartType === 'horizontalBar') {
      chartOptions.indexAxis = 'y'
    }

    if (chartData.scored) {
      if (chartData.datasets.length === 1) {
        chartOptions.legend = { display: false }
      }
      chartOptions.scales = {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }],
      }
    }

    if (chartData.displays_average_scores) {
      chartOptions.plugins = chartOptions.plugins || {}
      chartOptions.plugins.title = {
        text: 'Average Score',
        display: true,
      }
    }

    return chartOptions
  }
}
