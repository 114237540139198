import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'accessKeyInput', 'submit' ]

  #keyFormatSpec;

  connect() {
    this.#keyFormatSpec = this.#parseKeyFormatSpec()
    this.accessKeyInputTarget.addEventListener('input', this.#syncCallback())
  }

  #syncCallback() {
    const that = this
    return () => {
      that.submitTarget.disabled = that.#keyIsInAnUnexpectedFormat()
    }
  }

  #keyIsInAnUnexpectedFormat() {
    // If something goes wrong, fail open.
    if (!this.#keyFormatSpec) { return false }

    const expectedUnseparatedLength = this.#keyFormatSpec.chunkSize * this.#keyFormatSpec.chunkCount
    const key = this.accessKeyInputTarget.value
    return key.replaceAll(this.#keyFormatSpec.separator, '').length !== expectedUnseparatedLength
  }

  #parseKeyFormatSpec() {
    const spec = {
      separator: this.data.get('access-key-separator'),
      chunkSize: parseInt(this.data.get('access-key-chunk-size'), 10),
      chunkCount: parseInt(this.data.get('access-key-chunk-count'), 10),
    }

    if (
      typeof spec.separator === 'string'
      && !isNaN(spec.chunkSize)
      && !isNaN(spec.chunkCount)
    ) {
      return spec
    }

    return null
  }
}
