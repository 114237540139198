import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        const urlPatternMeetingVote = /\/m\/\w+$/;
        const urlPatternResultsPage = /\/results\/\w+/;

        const cameFromMeetingVote = urlPatternMeetingVote.test(document.referrer);
        const cameFromResults = urlPatternResultsPage.test(document.referrer);
        const visitedMeetingVote = sessionStorage.getItem('visitedMeetingVote') === 'true';

        if (cameFromMeetingVote) {
            this.showButton();
            sessionStorage.setItem('visitedMeetingVote', 'true');
        } else if (cameFromResults && visitedMeetingVote) {
            this.showButton();
        } else {
            this.hideButton();
            sessionStorage.removeItem('visitedMeetingVote');
        }
    }

    hideButton() {
        this.element.style.display = 'none';
    }

    showButton() {
        this.element.style.display = 'block';
    }
}