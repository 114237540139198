import { Controller } from 'stimulus'

export default class extends Controller {
  connect(): void {
    window.EB?.Elections?.VoterStatus?.reloadPaginatedInvitations()
  }

  closeModal(): void {
    window.EB?.TurboModal?.hideModal()
  }
}
