
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = [
    'logoUploadForm',
    'editLogoButton',
    'submitLogoButton',
    'logoFileInput',
    'logoImage',
    'noLogoLabel'
  ];

  declare logoUploadFormTarget: HTMLFormElement;
  declare editLogoButtonTarget: HTMLButtonElement;
  declare submitLogoButtonTarget: HTMLButtonElement;
  declare logoFileInputTarget: HTMLInputElement;
  declare logoImageTarget: HTMLImageElement;
  declare noLogoLabelTarget: HTMLElement;

  public connect() {
    this.fileUploadInputListener();
  }

  public clickFileInput(event: Event) {
    event.preventDefault();
    this.logoFileInputTarget.click();
  }

  private fileUploadInputListener() {
    this.logoFileInputTarget.onchange = () => {
      let fileUploaded:File = this.logoFileInputTarget.files[0];
      if (fileUploaded) {
        this.logoUploadFormTarget.classList.remove('no-logo');
        this.logoImageTarget.src = URL.createObjectURL(fileUploaded);
        this.logoImageTarget.classList.remove('hide');
        this.submitLogoButtonTarget.classList.remove('hide');
        this.editLogoButtonTarget.classList.add('hide');
        if (this.noLogoLabelTarget) this.noLogoLabelTarget.classList.add('hide');
      }
    }
  }
}
