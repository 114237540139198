import { Controller } from "stimulus"
import $ from 'jquery';
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["select"]

  connect() {
    $(this.selectTarget).multipleSelect({
      filter: true,
      minimumCountSelected: 5,
      onClick: this.getVotersCount.bind(this),
      onCheckAll: this.getVotersCount.bind(this),
      onUncheckAll: this.getVotersCount.bind(this)
    });
    const emptySelectedGroupIds = this.data.get('emptySelectedGroupIds') === 'true';
    if (emptySelectedGroupIds) {
      $(this.selectTarget).multipleSelect('checkAll')
    }
  }

  getVotersCount() {
    const selectedOptions = this.selectTarget.selectedOptions;
    const groupIds = Array.from(selectedOptions).map(option => option.value);
    const voteId = this.data.get('voteId');
    const noticeType = this.data.get('noticeType');
    const queryParams = new URLSearchParams({ group_ids: groupIds, notice_type: noticeType }).toString();
    const url = `/elections/${voteId}/notifications/notified_invitations_count?${queryParams}`;
    fetch(url, {
      method: 'GET',
      headers: { 'Accept': 'text/vnd.turbo-stream.html' },
      credentials: 'include'
    }).then(response => response.text()).then(html => Turbo.renderStreamMessage(html));
  }
}