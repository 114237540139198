import { Controller } from 'stimulus';

export class BaseController extends Controller {
  dispatch(
    eventName: string,
    config?:
      | Partial<{
          target: Element | Window | Document;
          detail: Object;
          bubbles: boolean;
          cancelable: boolean;
        }>
      | undefined
  ): CustomEvent<Object> {
    this.logger(`@${eventName}`, config);

    const updatedConfig = {
      ...config,
      prefix: '',
    };

    return super.dispatch(eventName, updatedConfig);
  }

  private logger(message, details?: {} | null): void {
    if (!this.application.debug) return;

    console.groupCollapsed(
      `%c${message}`,
      'color: #a2dbff; font-weight: bold;'
    );

    if (details) {
      console.log('details:', details);
    }

    console.groupEnd();
  }
}
