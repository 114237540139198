import { mapValues } from 'lodash';
import { BaseController } from '../../base_controller';
import { EVENTS as FORM_EVENTS } from '../form_component/form_component_controller';
import { EVENTS as KEY_OVERLAY_EVENTS } from '../key_overlay_component/key_overlay_component_controller';

const UNIQUE_ID = 'ballots--question-component';

export const EVENTS = (() => {
  const events = {
    QUESTION_FOCUSED: 'question_focused',
    ANSWER_INPUT_FOCUSED: 'answer_input_focused',
    ANSWER_INPUT_UNFOCUSED: 'answer_input_unfocused',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = ['container'];

  declare containerTarget: HTMLElement;

  static values = {
    orderIndex: Number,
    letterIdentifier: String,
    questionId: Number,
    advancedKeybindingActive: Boolean,
    questionType: String,
    keyOverlayIdentifier: String,
  };

  declare orderIndexValue: number;
  declare letterIdentifierValue: string;
  declare questionIdValue: number;
  declare focused: boolean;
  declare advancedKeybindingActiveValue: boolean;
  declare questionTypeValue: string;
  declare activeAnswerIndex: number | null;
  declare keyOverlayIdentifierValue: string;

  initialize(): void {
    this.addListeners();
  }

  // private methods

  private focus() {
    this.focused = true;
    this.scrollIntoView(this.containerTarget);
    this.containerTarget.classList.add('focus');
    this.emitQuestionFocused();
  }

  private scrollIntoView(element: HTMLElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }

  private removeFocus() {
    this.focused = false;
    this.containerTarget.classList.remove('focus');
    this.emitHideKeyPressed();
  }

  private focusQuestionByIndex({ index }) {
    this.orderIndexValue === index ? this.focus() : this.removeFocus();
  }

  private focusQuestionByLetter({ letter }) {
    this.letterIdentifierValue === letter ? this.focus() : this.removeFocus();
  }

  private findAnswerRow(answerIndex) {
    const selector = `[data-answer-row="${this.questionIdValue}_${answerIndex}"]`;
    return document.querySelector(selector) as HTMLElement;
  }

  private focusAnswerRow(answerIndex) {
    const row = this.findAnswerRow(answerIndex);

    if (!row) return;

    row.classList.add('focus');
  }

  private unfocusAnswerRow(answerIndex) {
    const row = this.findAnswerRow(answerIndex);

    if (!row) return;

    row.classList.remove('focus');
  }

  private findAnswer(answerIndex) {
    const selector = `[data-answer-index="${this.questionIdValue}_${answerIndex}"]`;
    return document.querySelector(selector) as HTMLElement;
  }

  private removeAnswerFocus(element: HTMLElement | null = null) {
    if (!this.focused || !this.activeAnswerIndex) return;

    const answer = element || this.findAnswer(this.activeAnswerIndex);

    if (!answer) return;

    answer.blur();
    this.clearListeners(answer);
    this.unfocusAnswerRow(this.activeAnswerIndex);
    this.activeAnswerIndex = null;
    this.emitAnswerInputUnfocused();
  }

  private clearListeners(element) {
    element.removeEventListener('change', () => {});
    element.removeEventListener('keydown', () => {});
  }

  private isWriteIn(element) {
    return Boolean(element.getAttribute('data-write-in'));
  }

  private isAbstain(element) {
    return Boolean(element.getAttribute('data-abstain'));
  }

  private addEnterKeyListener(element) {
    element.addEventListener('keydown', (event) => {
      if (event.key !== 'Enter') return;
      event.stopPropagation();
      event.preventDefault();
      this.removeAnswerFocus(element);
    });
  }

  private handleTextInput(element, referenceIndex) {
    this.activeAnswerIndex = referenceIndex;
    element.focus();
    element.click();
    this.emitAnswerInputFocused();
    this.focusAnswerRow(referenceIndex);
    this.addEnterKeyListener(element);
  }

  private handleSelectInput(element, referenceIndex) {
    this.activeAnswerIndex = referenceIndex;
    element.focus();
    element.showPicker();
    this.emitAnswerInputFocused();
    this.focusAnswerRow(referenceIndex);
    element.addEventListener('change', () => this.removeAnswerFocus(element));
  }

  private findWriteInAnswer(referenceIndex) {
    const selector = `[data-write-in-answer-index="${this.questionIdValue}_${referenceIndex}"]`;
    return document.querySelector(selector) as HTMLElement;
  }

  private handleWriteIn(element, referenceIndex) {
    this.activeAnswerIndex = referenceIndex;
    element.focus();
    element.click();
    this.emitAnswerInputFocused();
    this.focusAnswerRow(referenceIndex);
    this.addWriteInEnterKeyListener(element, referenceIndex);
  }

  private addWriteInEnterKeyListener(element, referenceIndex) {
    element.addEventListener('keydown', (event) => {
      if (event.key !== 'Enter') return;
      event.stopPropagation();
      event.preventDefault();
      this.clearListeners(element);

      const writeInAnswer = this.findWriteInAnswer(referenceIndex);

      if (!writeInAnswer) return this.removeAnswerFocus();

      if (['preferential'].includes(this.questionTypeValue)) {
        this.handleSelectInput(writeInAnswer, referenceIndex);
      }

      if (['plurality', 'cumulative'].includes(this.questionTypeValue)) {
        this.handleTextInput(writeInAnswer, referenceIndex);
      }
    });
  }

  private handleAbstain(element) {
    element.focus();
    element.click();
  }

  private checkAnswerByNumber({ number: answerReferenceIndex }) {
    if (!this.focused) return;

    const answerElement = this.findAnswer(answerReferenceIndex);

    if (!answerElement) return this.emitNotifyAnswerNotFound();

    this.scrollIntoView(answerElement);

    this.emitHideKeyPressed();

    if (this.isWriteIn(answerElement)) {
      return this.handleWriteIn(answerElement, answerReferenceIndex);
    }

    if (this.isAbstain(answerElement)) {
      return this.handleAbstain(answerElement);
    }

    if (['cumulative'].includes(this.questionTypeValue)) {
      this.handleTextInput(answerElement, answerReferenceIndex);
    }

    if (['scored', 'preferential'].includes(this.questionTypeValue)) {
      this.handleSelectInput(answerElement, answerReferenceIndex);
    }

    if (
      ['nomination', 'plurality', 'approval'].includes(this.questionTypeValue)
    ) {
      answerElement.focus();
      answerElement.click();
    }
  }

  // Listeners

  private addListeners() {
    if (!this.advancedKeybindingActiveValue) return;

    document.addEventListener(FORM_EVENTS.FOCUS_QUESTION_BY_INDEX, (event) => {
      this.focusQuestionByIndex(event.detail);
    });

    document.addEventListener(FORM_EVENTS.FOCUS_QUESTION_BY_LETTER, (event) => {
      this.focusQuestionByLetter(event.detail);
    });

    document.addEventListener(FORM_EVENTS.NUMBER_PRESSED, (event) => {
      this.emitShowKeyPressed(event.detail.number);
    });

    document.addEventListener(FORM_EVENTS.NUMBER_CLEARED, () => {
      this.emitHideKeyPressed();
    });

    document.addEventListener(FORM_EVENTS.CHECK_ANSWER_BY_NUMBER, (event) => {
      this.checkAnswerByNumber(event.detail);
    });

    document.addEventListener(FORM_EVENTS.CLEAR_FOCUS, () => {
      this.removeFocus();
    });
  }

  // Emitters

  private emitQuestionFocused() {
    this.dispatch(EVENTS.QUESTION_FOCUSED, {
      detail: {
        orderIndex: this.orderIndexValue,
        letterIdentifier: this.letterIdentifierValue,
      },
    });
  }

  private emitAnswerInputFocused() {
    this.dispatch(EVENTS.ANSWER_INPUT_FOCUSED);
  }

  private emitAnswerInputUnfocused() {
    this.dispatch(EVENTS.ANSWER_INPUT_UNFOCUSED);
  }

  private emitNotifyAnswerNotFound() {
    this.dispatch(KEY_OVERLAY_EVENTS.SHAKE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitShowKeyPressed(key) {
    if (!this.focused) return;

    this.dispatch(KEY_OVERLAY_EVENTS.SHOW, {
      detail: {
        key: key,
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitHideKeyPressed() {
    this.dispatch(KEY_OVERLAY_EVENTS.HIDE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }
}
