import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['form', 'formatInput', 'csvButton', 'excelButton']

  initialize() {
    this.$form = $(this.formTarget)
    $('.existing_download_modal').remove()
    $('#download_multiple_reports_modal_component').modal().addClass('existing_download_modal')

    this.$buttons = this.$form.find('.btn')
    this.$reportCheckboxes = this.$form.find('.report_type_checkbox')
      .on('change', this.handleCheckBoxChange.bind(this))
    this.$form.on('submit', this.handleFormSubmit().bind(this))

    this.$fomatInput = $(this.formatInputTarget)
    $(this.csvButtonTarget).on('click', () => { this.$fomatInput.val('csv') })
    $(this.excelButtonTarget).on('click', () => { this.$fomatInput.val('xls') })

    this.handleCheckBoxChange()
  }

  handleCheckBoxChange() {
    const buttonsShouldBeEnabled = !this.$reportCheckboxes.is(':checked')
    this.$buttons = this.$form.find('.btn').prop('disabled', buttonsShouldBeEnabled)
  }

  handleFormSubmit() {
    return () => {
      this.formTarget.submit()
    }
  }
}
