import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ['generatePDF', 'downloadPDF', 'form', 'noVotersMessage']

  connect() {
    this.tearoffCheckbox = this.element.querySelector('#include_ballot_tearoff')
    this.ballotCheckbox = this.element.querySelector('#include_ballot_tearoff')

    if (this.ballotCheckbox && !this.ballotCheckbox.checked) {
      this.tearoffCheckbox.checked = false
      this.tearoffCheckbox.disabled = true
    }
  }

  createSuccess(data) {
    this.noVotersMessageTarget.classList.add('hidden')
    if (data.detail[0].number_of_voters === 0) {
      this.noVotersMessageTarget.classList.remove('hidden')
      return
    }

    this.generatePDFTarget.classList.add('hidden')
    this.downloadPDFTarget.classList.remove('hidden')
    this.element.querySelectorAll('input').forEach(i => i.disabled = true);

    window.NProgress.configure({ parent: `#${this.element.id}` })
    window.NProgress.set(0.0)

    this.scheduledCheck = () => { this.schedulePrintedNoticesURLCheck(data.detail[0]) }
    this.scheduledCheck()
  }

  ballotCheckClicked(event) {
    if (!this.tearoffCheckbox) return

    if (event.target.checked) {
      this.tearoffCheckbox.checked = false
      this.tearoffCheckbox.disabled = false
      this.tearoffCheckbox.checked = this.tearoffCheckboxPreviousValue
    } else {
      this.tearoffCheckboxPreviousValue = this.tearoffCheckbox.checked
      this.tearoffCheckbox.checked = false
      this.tearoffCheckbox.disabled = true
    }
  }

  schedulePrintedNoticesURLCheck(data) {
    $.get(data.printed_notices_path, this.parseNoticeProgressCheckResponse.bind(this))
  }

  parseNoticeProgressCheckResponse(printed_notices) {
    const printed_notices_url = printed_notices && printed_notices.url || ''
    const printed_notices_progress = printed_notices && printed_notices.progress || 0.0

    if (printed_notices_url != '') {
      this.element.classList.remove('window.NProgress-custom-parent')
      window.NProgress.configure({ parent: 'body' })
      window.NProgress.set(1.0)

      const url = "window.open('" + printed_notices_url + "', '_blank'); return false"
      $('#download_pdf').html('<button onclick="' + url + '" class="btn btn--orange">Download</button>')
    } else {
      window.NProgress.set(printed_notices_progress)
      setTimeout(this.scheduledCheck.bind(this), 1000)
    }
  }
}

