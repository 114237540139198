import { Controller } from "stimulus"
import $ from 'jquery'
import NProgress from 'nprogress'

export default class extends Controller {
  static targets = ['input']

  connect() {
    let electionId = this.inputTarget.dataset.electionId;
    if (electionId === null || electionId === "") {
      return;
    }
    NProgress.start();
    let scrollTopOption = this.inputTarget.dataset.scrollTopOption;
    if (scrollTopOption == 'true') {
      $("html, body").animate({ scrollTop: $("#import_options_title").offset().top }, 'slow');
    }
    this.scheduleVoterListProcessingStatusCheck(electionId)
  }

  scheduleVoterListProcessingStatusCheck(electionId) {
    $.get( `voter_list_import_status/${electionId}`, (processingStatus) => {
      let processingDone = processingStatus && processingStatus.done || false
      let action = processingStatus && processingStatus.action || ""
      if (processingDone) {
        $.get('voter_list_import')
      } else {
        if(action === 'validation') {
          $('#voter_list_processing_message').text('Validating voters.')
        } else if(action === 'importation') {
          $('#voter_list_processing_message').text('Importing and validating voters.')
        } else if(action === 'copy_voters') {
          $('#voter_list_processing_message').text('Copying voters.')
        }
        setTimeout(() => { this.scheduleVoterListProcessingStatusCheck(electionId)}, 500)
      }
    })
  }
}
