import consumer from "./consumer"

consumer.subscriptions.create("FlashMessageChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    let body = document.querySelector(`.${data.page}`);
    if(body) {
      let flash = document.createElement('div');
      flash.classList.add('flash', `flash_${data.type}`);
      flash.innerHTML = data.message;
      body.insertBefore(flash, body.firstChild);
    }
  }
});
