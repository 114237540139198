import { Controller } from 'stimulus'
import axios from 'axios';


export default class extends Controller {
  static targets = ['obfuscation', 'electionId']

  connect() {
    this.electionId = parseInt(this.electionIdTarget.value);
  }

  toggle(event) {
    const message = 'Showing the weighted vote by vote detail may allow linking of voters to their choices using the voting weights. This cannot be undone. Proceed?'

    if(this.obfuscationTarget.checked) {
      return event.preventDefault()
    }

    if(confirm(message)) {
      axios.post(
        `/elections/${this.electionId}/obfuscation`,
        {},
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        }
      ).then(() => {
        window.location.reload();
      })

      this.obfuscationTarget.disabled = true
    } else {
      event.preventDefault()
    }
  }
}
