import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = ['link'];

  declare linkTarget: HTMLAnchorElement;
  declare meetsWindow: Window;

  public click(event: Event) {
    event.preventDefault();
    if (this.meetsWindow && !this.meetsWindow.closed) {
      this.meetsWindow.focus();
    } else {
      this.meetsWindow = window.open(this.linkTarget.href, this.linkTarget.target);
    }
  }
}
