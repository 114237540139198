import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal']

  declare modalTarget: HTMLElement & { isOpened: boolean }

  // classNames of "progress" elements that when clicked will not close the modal
  private progressClassNames: string[] = ['nprogress-busy']

  public connect(): void {
    this.openModal();
    window.EB = window.EB || {}
    window.EB.TurboModal = this
  }

  public openModal(): void {
    if (!this.modalTarget.isOpened) {
      this.modalTarget.style.display = 'inline-block';
    }
  }

  public hideModal(): void {
    this.element.parentElement.removeAttribute("src");
    this.modalTarget.remove();
    this.findHTMLElement().remove();
  }

  // hide modal when clicking ESC
  public closeWithKeyboard(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.hideModal()
    }
  }

  public closeBackground(event): void {
    if (event && this.blockModalClosing(event)) return;

    this.hideModal()
  }

  // Private Methods

  private findHTMLElement() {
    return document.querySelector('.turbo-modal-component')!
  }

  private blockModalClosing(event): boolean {
    return (
      this.modalTarget.contains(event.target) ||
      event.target.type === "search" ||
      this.progressClassNames.includes(event.target.className)
    );
  }
}
