import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $(function () {
      $('select').multipleSelect({
        selectAll: false,
        allSelected: false,
        placeholder: 'Select the filters',
        animate: 'slide',
      })

      const filters = $('#js-filter-params').data('filters')
      const submitted = $('#js-filter-params').data('submitted')

      if (typeof (filters) != 'undefined') {
        const parsed_filters = JSON.parse(filters)

        if (parsed_filters != null) {
          $('select').multipleSelect('setSelects', parsed_filters)
        }
      }

      if (!submitted) {
        $('select').multipleSelect('setSelects', ['election_live'])
      }
    })
  }
}
