import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['customHelpText']

  declare customHelpTextTarget: HTMLInputElement;

  public enableCustomHelpTextInput(): void {
    this.customHelpTextTarget.classList.remove('hide');
  }

  public disableCustomHelpTextInput(): void {
    this.customHelpTextTarget.querySelector('.rich_text_editor_component_container .ql-editor').innerHTML = '';
    this.customHelpTextTarget.classList.add('hide');
  }
}
