import {Controller} from 'stimulus';
import $ from 'jquery'

export default class extends Controller {
  static targets = ['form', 'deleteUserCheckbox'];

  connect() {
    this.IndustryFieldSetup()
  }

  IndustryFieldSetup() {
    const select = this.element.querySelector("select")
    $(select).on('change', (event: Event) => {
      this.formTarget.requestSubmit();
    })
  }

  handleCheckboxChange(event: Event) {
    this.formTarget.requestSubmit();
  }

  handleDeleteUser(event: Event) {
    if (confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      this.formTarget.requestSubmit();
    }
  }
}