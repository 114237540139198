import { mapValues } from 'lodash';
import { BaseController } from '../../../base_controller';

const UNIQUE_ID = 'ballot-design--wizard--prior-question-results-component';

export const EVENTS = (() => {
  const events = {
    LOAD_ADDITIONAL_RESULTS: 'load_additional_results',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = ['questionPreview', 'showQuestionLink'];

  declare questionPreviewTargets: HTMLInputElement[];
  declare showQuestionLinkTargets: HTMLLinkElement[];

  public viewMore(): void {
    this.emitLoadAdditionalResults();
  }

  public toggleQuestionPreview(event) {
    const target = event.target;
    const questionIndex = target.id.split('-').pop();

    this.updateQuestionPreviewVisibility(questionIndex);
    this.hideAllPreviews(questionIndex);
    this.revertLinks(questionIndex);
    this.updateTriggerLabel(target);
  }

  private hideAllPreviews(questionIndex) {
    const elements = this.questionPreviewTargets.filter(
      ({ id }) => id !== `question-preview-${questionIndex}`
    );

    elements.forEach((element) => {
      element.classList.remove('visible');
    });
  }

  private revertLinks(questionIndex) {
    const elements = this.showQuestionLinkTargets.filter(
      ({ id }) => id !== `show-question-link-${questionIndex}`
    );

    elements.forEach((element) => {
      element.textContent = 'View';
    });
  }

  private updateTriggerLabel(target: HTMLElement) {
    if (target.textContent === 'View') {
      target.textContent = 'Hide';
    } else {
      target.textContent = 'View';
    }
  }

  private updateQuestionPreviewVisibility(questionIndex) {
    const element = this.questionPreviewTargets.find(
      ({ id }) => id === `question-preview-${questionIndex}`
    );

    if (!element) return;

    element.classList.toggle('visible');
  }

  private emitLoadAdditionalResults() {
    this.dispatch(EVENTS.LOAD_ADDITIONAL_RESULTS, {
      // @ts-ignore
      prefix: null,
    });
  }
}
