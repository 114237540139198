import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['banner']

  declare bannerTarget: HTMLElement;

  connect(): void {
    this.changeBannerToError();
  }

  private changeBannerToError(): void {
    if(document.querySelectorAll('#link-error').length > 0){
      this.bannerTarget.classList.remove('green-banner');
      this.bannerTarget.classList.add('red-banner');
    }
  }
}
