import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'select', 'text' ]
  static values = {
    tags: Boolean
  }
  initialize() {
    let selectTarget = $(this.selectTarget)

    selectTarget.select2({
      placeholder: 'Select',
      matcher: this.matchCustom,
      width: '50%',
      tags: this.tagsValue,
    })

    // Adds a placeholder to the search input
    // Based on https://forums.select2.org/t/additional-placeholder-in-search-input/325/2
    const placeHolder = this.tagsValue ? 'Search or add a new option' : 'Search...';
    selectTarget.one('select2:open', function(e) {
        $('input.select2-search__field').prop('placeholder', placeHolder);
    });

    this.displayOrganizationTypeOther()

    // Adding an onchange through a stimulus data-action doesn't seem to work
    // with the select2 jQuery plugin.
    selectTarget.on('change', this.displayOrganizationTypeOther.bind(this))
  }

  displayOrganizationTypeOther() {
    if ($(this.selectTarget).find('option:selected').text().toLowerCase().match(/\bother\b/)) {
      $(this.textTarget).show();
    } else {
      $(this.textTarget).val('').hide();
    }
  }

  matchCustom(params, data) {
    const childrenNodes = data?.children || [];
    const nodeId = data?.id || null;
    const parentNodeName = data.text.toUpperCase();
    const term = (params?.term?.toUpperCase() || '').trim();

    let filteredNodes = [];
    let modifiedData = data;

    if (term === '') return modifiedData;
    if (childrenNodes.length === 0 && nodeId === null) return null;

    const nodeMatched = parentNodeName.indexOf(term) !== -1

    if (nodeMatched && childrenNodes.length === 0) {
      modifiedData = {};
      filteredNodes.push(data);
    } else if (nodeMatched) {
      filteredNodes = childrenNodes;
    } else {
      filteredNodes = childrenNodes.filter((child) => {
        return child.text.toUpperCase().indexOf(term) !== -1;
      });
    }

    if (filteredNodes.length === 0) return null;

    modifiedData = $.extend({}, modifiedData, true);
    modifiedData.children = filteredNodes;

    return modifiedData;
  }
}
