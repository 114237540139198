import { Controller } from 'stimulus'
import axios from 'axios'

const HOW_LONG_TO_FLASH_MESSAGE = 2000

export default class extends Controller {
  static targets = ['startReorderButton', 'endReorderButton']

  initialize() {
    if (this.element.dataset.disableReorder) return

    this.count = 0
    this.selectedIds = []
    this.rowObjects = {}
    this.rowOrder = []
    this.reorderModeOn = false
    this.element.parentNode.style.position = 'relative'

    this.tableBody = this.element.querySelector('tbody')
    this.element.classList.add('reoderable_table_controller')

    this.cloneTable()
    this.createPlaceHolderElement()
    this.indexRows()

    this.setupStartReorderModeButton()
    this.setupEndReorderModeButton()
    this.createMultipleRowsAreSelectableMessage()
  }

  setupStartReorderModeButton() {
    this.startReorderButtonTarget.addEventListener('click', this.startReorderMode.bind(this))
    this.startReorderButtonTarget.classList.remove('hide')
    this.startReorderButtonIcon = this.startReorderButtonTarget.querySelector('i')
    this.startReorderButtonText = this.startReorderButtonTarget.querySelector('span')
  }

  setupEndReorderModeButton() {
    this.endReorderButtonTarget.addEventListener('click', this.handleSaveChangesButtonClick.bind(this))
    this.endReorderButtonTarget.classList.add('hide')
    this.endReorderButtonIcon = this.endReorderButtonTarget.querySelector('i')
    this.endReorderButtonText = this.endReorderButtonTarget.querySelector('span')
  }

  createMultipleRowsAreSelectableMessage() {
    this.multipleRowsAreSelectableMessage = document.createElement('span')
    this.multipleRowsAreSelectableMessage.classList.add('multiple-Selectable-Message')
    this.multipleRowsAreSelectableMessage.textContent = 'Select 1 or more votes then reorder'
    this.startReorderButtonTarget.parentNode.insertBefore(
      this.multipleRowsAreSelectableMessage, this.startReorderButtonTarget
    )
  }

  createPlaceHolderElement() {
    // this is the element placed in the table during a drag to show where the rows
    // will be moved to when the user stops dragging
    this.placeHolderElement = this.tableBody.querySelectorAll('tr')[0].cloneNode(true)
    this.placeHolderElement.classList.add('dragging-placeholder')
  }

  createRowObject(rowElement, index) {
    const rowObject = {}
    const id = rowElement.dataset.voteId
    this.rowOrder.push(id)
    rowObject.id = id
    rowObject.position = index
    rowObject.originalElement = rowElement
    rowObject.cloneElement = this.draggingTable.querySelector(`tr[data-vote-id="${id}"]`)
    rowObject.cloneElement.remove()
    this.rowObjects[id] = rowObject
  }

  indexRows() {
    // to avoid using querySelector hundreds of time each time an element needs to be
    // manipulated, I index the rows and select their elements once.
    this.allRows = this.tableBody.querySelectorAll('tr')
    this.allRows.forEach(this.createRowObject.bind(this))
    const rowObjectsAsArray = Object.entries(this.rowObjects).map((entry) => entry[1])
    rowObjectsAsArray.forEach((rowObject) => this.addRowEventListeners(rowObject))
    this.originalRowsOrder = rowObjectsAsArray.map((rowObject) => rowObject.id)
    this.latestRowsOrder = [...this.originalRowsOrder]

    document.addEventListener('mouseup', this.handleDragEnd.bind(this))
    this.element.addEventListener('mousemove', this.positionDraggingTable.bind(this))

    this.allRowsCheckboxes = this.element.querySelectorAll('input[type="checkbox"]')
    this.allHandleButtons = []
    this.allRowsCheckboxes.forEach((checkbox) => {
      const handleButton = document.createElement('button')
      const handleIcon = document.createElement('i')
      handleButton.classList = 'btn btn--dark-blue btn--with-icon btn--only-icon btn--small hide'
      handleIcon.classList = 'fas fa-sort reorderable-table-handle-icons'
      handleButton.appendChild(handleIcon)
      this.allHandleButtons.push(handleButton)
      checkbox.parentElement.appendChild(handleButton)
    })
  }

  addRowEventListeners({ originalElement }) {
    originalElement.addEventListener('click', this.handleRowClick.bind(this))
    originalElement.querySelector('td:first-of-type').addEventListener('mousedown', this.handleDragStart.bind(this))
    originalElement.addEventListener('mousemove', this.handleDrag.bind(this))
    originalElement.addEventListener('mouseup', this.handleDragEnd.bind(this))
  }

  cloneTable() {
    // the element that follows the cursor during a drag is a cloned copy of the table
    // with the selected rows made visible. This function creates that cloned copy
    // of the table
    this.rectDimensions = this.element.getBoundingClientRect()
    this.tableWidth = parseInt(window.getComputedStyle(this.element).width, 10)
    this.list = document.createElement('div')
    this.list.style.left = `${this.rectDimensions.left}px`
    this.list.style.top = `${this.rectDimensions.top}px`
    this.list.classList.add('dragging-list')

    this.element.parentNode.insertBefore(this.list, this.element)
    this.draggingTable = this.tableBody.cloneNode(true)
    this.draggingTable.classList.add('hide')
    this.draggingTable.querySelectorAll('[id]').forEach((node) => {
      // to avoid having duplicate IDs on the document all elements with an `id`
      // attribute is selected and has its `id` modified
      node.id = `${node.id}_cloned`
    })
    const originalRowWithCorrectWidth = this.tableBody.querySelectorAll('tr:first-of-type td')
    this.draggingTable.querySelectorAll('tr').forEach((trElement) => {
      // the width of td has to be copied over
      trElement.querySelectorAll('td').forEach((td, index) => {
        td.style.width = `${originalRowWithCorrectWidth[index].clientWidth}px`
      })
    })
    this.list.appendChild(this.draggingTable)
  }

  handleRowClick({ target }) {
    if (!this.reorderModeOn) return

    const targetRow = target.closest('tr')
    const targetRowId = targetRow.dataset.voteId
    const targetRowObject = this.rowObjects[targetRowId]

    if (this.selectedIds.includes(targetRowId)) {
      this.unselectRow(targetRowObject)
    } else {
      this.selectRow(targetRowObject)
    }
  }

  selectRow(rowObject) {
    if (this.selectedIds.includes(rowObject.id)) return

    this.selectedIds.push(rowObject.id)
    rowObject.selected = true
    rowObject.originalElement.classList.add('selected-for-reorder')
    this.draggingTable.appendChild(rowObject.cloneElement)
  }

  unselectRow(rowObject) {
    rowObject.selected = false
    rowObject.originalElement.classList.remove('selected-for-reorder')
    rowObject.cloneElement.remove()
  }

  handleDragStart({ target }) {
    if (!this.reorderModeOn) return

    this.dragInProgress = true
    this.draggingTable.classList.remove('hide')

    const originalRow = target.closest('tr')
    originalRow.classList.add('selected-for-reorder')
    this.element.classList.add('dragInProgress')

    this.draggingRowIndex = [].slice.call(this.element.querySelectorAll('tr')).indexOf(originalRow)
  }

  handleDrag({ target, clientY }) {
    if (!this.reorderModeOn || !this.dragInProgress) return

    const targetRow = target.closest('tr')
    if (!targetRow) return

    const targetRowId = targetRow.dataset.voteId
    const targetRowObject = this.rowObjects[targetRowId]
    const nothingSelected = this.selectedIds.length == 0

    if (nothingSelected) {
      this.selectRow(this.rowObjects[targetRowId])
    }

    const { top, height } = target.getBoundingClientRect()
    const thirdOfHeight = height / 3

    const mouseVerticalPositionOverRow = clientY - top

    if (mouseVerticalPositionOverRow < thirdOfHeight) {
      this.placeHolderIdealPosition = 'before'
    } else if (thirdOfHeight * 2) {
      this.placeHolderIdealPosition = 'after'
    }

    if (!targetRowObject.hasPlaceholder || this.placeHolderCurrentPosition !== this.placeHolderIdealPosition) {
      this.insertPlaceHolder(targetRowId)
    }

    this.selectedIds.forEach((id) => this.rowObjects[id].originalElement.classList.add('being-dragged'))
  }

  positionDraggingTable({ clientY, clientX }) {
    // this method is used to position the table that follows the cursor to be
    // near the cursor
    if (!this.reorderModeOn || !this.dragInProgress) return

    this.list.classList.remove('hide')

    const tableY = clientY - this.rectDimensions.top + window.pageYOffset
    const tableX = clientX - this.rectDimensions.left

    this.list.style.left = `${tableX}px`
    this.list.style.top = `${tableY}px`
  }

  insertPlaceHolder(targetRowId) {
    const targetRowElement = this.rowObjects[targetRowId].originalElement

    if (this.placeHolderIdealPosition == 'before') {
      targetRowElement.parentElement.insertBefore(this.placeHolderElement, targetRowElement)
    }
    if (this.placeHolderIdealPosition == 'after') {
      if (!this.isLastRowId(targetRowId)) {
        targetRowElement.parentElement.insertBefore(this.placeHolderElement, targetRowElement.nextElementSibling)
      } else {
        this.tableBody.appendChild(this.placeHolderElement)
      }
    }

    this.placeHolderCurrentPosition = this.placeHolderIdealPosition
    this.rowWithPlaceHolder = targetRowId
  }

  isLastRowId(id) {
    id === this.rowOrder[this.rowOrder.length - 1]
  }

  handleDragEnd() {
    if (!this.reorderModeOn || !this.dragInProgress) return

    this.draggingTable.classList.add('hide')
    this.element.classList.remove('dragInProgress')
    this.selectedIds.forEach((id) => this.rowObjects[id].originalElement.classList.remove('being-dragged'))
    this.dragInProgress = false
    if (this.selectedIds.length == 0) return

    this.selectedIds.forEach(id =>{
      const rowObject = this.rowObjects[id]
      this.placeHolderElement.parentElement.insertBefore(rowObject.originalElement, this.placeHolderElement)
      this.unselectRow(rowObject)
    })
    this.placeHolderElement.remove()
    this.selectedIds = []
    this.latestRowsOrder = Array.from(this.tableBody.querySelectorAll('tr')).map(row => row.dataset.voteId)
  }

  toggleReorderMode() {
    if (this.reorderModeOn) {
      this.handleSaveChangesButtonClick()
    } else {
      this.startReorderMode()
    }
  }

  startReorderMode() {
    this.reorderModeOn = true
    this.element.classList.add('in-reorder-mode')
    this.changeToggleButtonsVisiblity()
    this.allRowsCheckboxes.forEach((checkbox) => { checkbox.labels.forEach((label) => label.classList.add('hide')) })
    this.allHandleButtons.forEach((handleButton) => { handleButton.classList.remove('hide') })
  }

   handleSaveChangesButtonClick() {
    this.element.classList.remove('in-reorder-mode')
    this.allRowsCheckboxes.forEach((checkbox) => { checkbox.labels.forEach((label) => label.classList.remove('hide')) })
    this.allHandleButtons.forEach((handleButton) => { handleButton.classList.add('hide') })

    this.selectedIds.forEach((voteId) => {
      this.rowObjects[voteId].originalElement.classList.remove('selected-for-reorder')
    })

    if (this.orderHasChanged() == false) {
      this.endReorderMode()
      this.selectedIds = []
      return
    }

    this.showUpdateInProgress()

    axios.get(
      this.element.dataset.updatePath,
      {
        params: {
          votes_in_order: this.latestRowsOrder,
          offset: parseInt(this.element.dataset.offset, 10)
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }
    ).then((result) => {
      this.originalRowsOrder = this.latestRowsOrder
      this.showUpdateSuccess()
      this.endReorderMode()
    }).catch((result) => {
      this.showUpdateFailure()
    })
  }

  endReorderMode() {
    this.reorderModeOn = false
    this.changeToggleButtonsVisiblity()
  }

  changeToggleButtonsVisiblity() {
    if (this.reorderModeOn) {
      this.startReorderButtonTarget.classList.add('hide')
      this.endReorderButtonTarget.classList.remove('hide')
    } else {
      this.startReorderButtonTarget.classList.remove('hide')
      this.endReorderButtonTarget.classList.add('hide')
    }
  }

  showUpdateInProgress() {
    this.endReorderButtonText.textContent = 'Saving Changes'
    this.endReorderButtonIcon.classList.remove('fa-exclamation-circle', 'fa-bars')
    this.endReorderButtonIcon.classList.add('fa-spinner')
  }

  showUpdateSuccess() {
    this.startReorderButtonIcon.classList.add('fa-check')
    this.startReorderButtonIcon.classList.remove('fa-sort')
    setTimeout(this.clearUpdateSuccessOrFailureMessage.bind(this), HOW_LONG_TO_FLASH_MESSAGE)
  }

  showUpdateFailure() {
    this.endReorderButtonIcon.classList.add('fa-exclamation-circle')
    this.endReorderButtonIcon.classList.remove('fa-bars', 'fa-spinner')
    this.endReorderButtonText.textContent = 'Update failed, try again'
    setTimeout(this.clearUpdateSuccessOrFailureMessage.bind(this), HOW_LONG_TO_FLASH_MESSAGE * 2)
  }

  clearUpdateSuccessOrFailureMessage() {
    this.endReorderButtonIcon.classList.remove('fa-exclamation-circle', 'fa-spinner')
    this.endReorderButtonIcon.classList.add('fa-bars')
    this.endReorderButtonText.textContent = 'Save Order'

    this.startReorderButtonIcon.classList.remove('fa-check')
    this.startReorderButtonIcon.classList.add('fa-sort')
    this.startReorderButtonText.textContent = 'Reorder Votes'
  }

  orderHasChanged() {
    const defaultValue = false
    const latestRowsOrder = this.latestRowsOrder
    if (this.originalRowsOrder.length !== latestRowsOrder.length) {
      // if this happens something is seriously wrong since the number of votes
      // should stay the same during the reordering process
      return true
    }

    return this.originalRowsOrder.reduce((orderHasChanged, individualValue, index) => {
      if (orderHasChanged == true) return true

      return !(individualValue == latestRowsOrder[index])
    }, defaultValue)
  }
}
