import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  declare submitButtonTarget: HTMLButtonElement;

  connect(): void {
    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        this.reload_location(event);
      }
    })

    this.remove_old_modal();
  }

  public enableSubmitButton(_event) {
    this.submitButtonTarget.disabled = false;
  }

  private reload_location(_event) {
    location.reload();
  }

  private remove_old_modal() {
    const modal = document.getElementsByClassName('add-to-vote-box-component')

    for (let element of modal) {
      if(element.style.display === 'none'){
        element.remove();
      }
    }
  }
}
