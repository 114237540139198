// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'channels';
import { Application, defaultSchema } from 'stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

// Stimulus reads the event.key
const customSchema = {
  ...defaultSchema,
  keyMappings: {
    ...defaultSchema.keyMappings,
    delete: 'Delete',
    numpadMinus: '-',
  },
};

const application = Application.start(document.documentElement, customSchema);
const context = require.context('controllers', true, /.(js|ts)$/);
const context_components = require.context(
  '../../components',
  true,
  /_controller.(js|ts)$/
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(context_components)
  )
);

application.debug = process.env.RAILS_ENV === 'development';

// Pick up the client timezone for localtime conversions on the site.
// We use local_time gem throughout for easy localization for logged in users,
// but we still need to determine and save (server-side) the tz for users
// so we have a default timezone to show to voters.
import Cookies from 'js-cookie';
import jstz from 'jstz';
Cookies.set('timezone', jstz.determine().name(), {
  path: '/',
  secure: true,
  expires: 365,
  SameSite: 'strict',
});

import '../components';
import I18n from 'i18n-js/index.js.erb';
window.I18n = I18n;

import '@hotwired/turbo-rails';

// Opt-in approach, our legacy code is not fully compatible with Turbo
Turbo.session.drive = false;

import 'chartkick/chart.js';

import '../pagy/pagy.js.erb';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill';
