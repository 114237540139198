import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  formChange(event) {
    if (event.target.name === 'organization[webhook_mode]') {
      this.formTarget.submit()
    }
  }
}
