import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [
    'copyVoterListCheckbox',
    'cantBeCopiedHelpText',
    'isTestRadio',
  ];

  connect() {
    this.voterListUnusableForTest =
      parseInt(this.element.dataset.sourceVoterListLength, 10) > 6;
    if (this.voterListUnusableForTest && this.isTestRadioTarget.checked) {
      this.copyVoterListCheckboxTarget.checked = false;
      this.copyVoterListCheckboxTarget.disabled = true;
    }
  }

  updateForm(event) {
    if (!this.voterListUnusableForTest) return;

    const isTest = event.target.checked && event.target.value === 'true';
    if (isTest) {
      this.previousCopyVoterListCheckboxValue = this.copyVoterListCheckboxTarget.checked;
      this.copyVoterListCheckboxTarget.checked = false;
      this.copyVoterListCheckboxTarget.disabled = true;
      this.cantBeCopiedHelpTextTarget.classList.remove('hidden');
    } else {
      this.copyVoterListCheckboxTarget.checked =
        this.previousCopyVoterListCheckboxValue;
      this.copyVoterListCheckboxTarget.disabled = false;
      this.cantBeCopiedHelpTextTarget.classList.add('hidden');
    }
  }
}
