import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'output', 'disable' ]

  initialize() {
    // We have to manually add our 'with_errors' CSS state if organization location
    // is invalid on submission, as our text field and label are "dummy" fields.
    this.applyErrorState()
    this.fetchLocation()
  }

  applyErrorState() {
    var f = this.inputTarget;
    // Previous sibling can be the label, so check that too.
    if ($(f).hasClass('with_errors') || $(f).siblings().hasClass('with_errors')) {
      $(f).parent().children().addClass('with_errors');
    }
  }

  fallbackEnableSubmit() {
    // Microsoft Edge (some versions?) seem to blow up on the API call below,
    // and the submit button isn't re-enabled. So we force it to be after 2
    // seconds. We don't want to interfere with signups!
    this.disableTarget.disabled = false;
  }

  fetchLocation() {
    var autocomplete = new google.maps.places.Autocomplete(this.inputTarget, { types: ['(cities)']});
    var that = this;
    google.maps.event.addDomListener(this.inputTarget, 'keydown', function(event) {
      if (event.keyCode === 13) {
          event.preventDefault();
      }
    });
    google.maps.event.addListener(autocomplete, 'place_changed', function (event) {
        that.disableTarget.disabled = true;
        setTimeout(function() { that.fallbackEnableSubmit() }.bind(that), 2000);
        var place = autocomplete.getPlace();
        var location = {
          address_components: place.address_components,
          formatted_address: place.formatted_address,
          lat: place.geometry.location.lat().toFixed(2),
          lng: place.geometry.location.lng().toFixed(2),
          utc_offset: place.utc_offset_minutes
        };
        that.outputTarget.value = JSON.stringify(location);
        that.disableTarget.disabled = false;
    });
  }
}
