import { BaseController } from '../../../base_controller';
import { mapValues } from 'lodash';
import {
  EVENTS as ITEM_EVENTS,
  findVoteItemElements,
} from '../vote_item_component/vote_item_component_controller';

import { EVENTS as FILTER_EVENTS } from '../select_filters_component/select_filters_component_controller';

const UNIQUE_ID = 'votes--result-list-component';

export const EVENTS = (() => {
  const events = {
    CHECK_ALL: 'check_all',
    UNCHECK_ALL: 'uncheck_all',
    GET_MULTI_ACTIONS: 'get_multi_actions',
    CHECKBOX_TOOGLE: 'checkbox_toogle',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = ['checkboxInput'];

  declare checkboxInputTarget: HTMLInputElement;

  initialize(): void {
    this.addListeners();
  }

  voteItemCheckAll(event) {
    if (event.target.checked) {
      this.handleCheck();
    } else {
      this.handleUncheck();
    }
  }

  private handleCheck() {
    this.emitCheckAll();
    this.emitGetMultiActions();
  }

  private handleUncheck() {
    this.emitUncheckAll();
  }

  private handleExternalCheck() {
    if (this.allElectionsSelected()) {
      this.updateCheckboxValue(true);
    }
  }

  private handleExternalUncheck() {
    if (!this.allElectionsSelected()) {
      this.updateCheckboxValue(false);
    }

    if (this.anyElectionSelected()) {
      this.emitGetMultiActions();
    }
  }

  private allElectionsSelected() {
    const elements = findVoteItemElements();

    return elements.every((el) => el.checked);
  }

  private anyElectionSelected() {
    const elements = findVoteItemElements();

    return elements.some((el) => el.checked);
  }

  private updateCheckboxValue(value: boolean) {
    this.checkboxInputTarget.checked = value;
    this.emitCheckboxToogle(value);
  }

  private updateCheckboxDisabledStatus(value: boolean) {
    this.checkboxInputTarget.disabled = value;
  }

  // Events

  private addListeners() {
    document.addEventListener(ITEM_EVENTS.CHECK, () => {
      this.handleExternalCheck();
    });

    document.addEventListener(ITEM_EVENTS.UNCHECK, () => {
      this.handleExternalUncheck();
    });

    document.addEventListener(ITEM_EVENTS.DISABLED_STATUS, (event) => {
      this.updateCheckboxDisabledStatus(event.detail.value);
    });

    document.addEventListener(FILTER_EVENTS.CHECK_ALL, () => {
      this.handleCheck();
    });

    document.addEventListener(FILTER_EVENTS.UNCHECK_ALL, () => {
      this.handleUncheck();
    });
  }

  private emitCheckAll() {
    // TODO, this being async could generate an inconsistency
    this.dispatch(EVENTS.CHECK_ALL, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitUncheckAll() {
    this.dispatch(EVENTS.UNCHECK_ALL, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitGetMultiActions() {
    this.dispatch(EVENTS.GET_MULTI_ACTIONS, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitCheckboxToogle(value: boolean) {
    this.dispatch(EVENTS.CHECKBOX_TOOGLE, {
      // @ts-ignore
      prefix: null,
      detail: {
        value,
      },
    });
  }
}
