import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['languageSelect']

  declare languageSelectTarget: HTMLInputElement;

  initialize(): void {
    let languageSelect = $(this.languageSelectTarget).select2()

    languageSelect.on('change', () => {
      let selectedLocale = languageSelect.val();
      let url = new URL(window.location.href);

      url.searchParams.set('locale', selectedLocale);
      window.location.href = url.href;
    })
  }
}
