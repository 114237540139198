import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["noConsentTerms", "defaultConsentTermsCheckbox", "customConsentTermsCheckbox", "defaultConsentTerms", "customConsentTerms"];

  connect() {
    let consentTermsText = document.querySelector('#consent_terms_text')

    if(consentTermsText !== null) {
      consentTermsText.removeAttribute('id');
    }
  }

  disableConsentTerms() {
    this.customConsentTermsCheckboxTarget.checked = false;
    this.defaultConsentTermsTarget.classList.add('hide');
    this.customConsentTermsTarget.classList.add('hide');
  }

  enableDefaultConsentTerms() {
    this.customConsentTermsCheckboxTarget.checked = false;
    this.defaultConsentTermsTarget.classList.remove('hide');
    this.customConsentTermsTarget.classList.add('hide');
  }

  enableCustomConsentTerms() {
    this.noConsentTermsTarget.checked = false;
    this.defaultConsentTermsCheckboxTarget.checked = false;
    this.defaultConsentTermsTarget.classList.add('hide');
    this.customConsentTermsTarget.classList.remove('hide');
  }
}
