import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['verticalList', 'headContainer', 'titleContainer'];

  declare verticalListTarget: HTMLElement;
  declare headContainerTarget: HTMLElement;
  declare titleContainerTarget: HTMLElement;
  declare hasHeadContainerTarget: Function;

  initialize(): void {
    this.appendVerticalList();
    this.defineContainerDirection();
  }

  public openMenu(): void {
    this.showMenu();
  }

  private showMenu(): void {
    if (!this.isMobileScreen()) {
      return;
    }

    this.verticalListTarget.classList.toggle('visible');
  }

  private appendVerticalList(): void {
    const activeItem = document.querySelector('#item-container.active')!;

    activeItem.appendChild(this.verticalListTarget);
  }

  private defineContainerDirection(): void {
    if (!this.hasHeadContainerTarget) return;

    if (this.isMobileScreen() && this.exceedsWindowWidth()) {
      this.headContainerTarget.style.flexDirection = 'column-reverse';
    } else {
      this.headContainerTarget.style.flexDirection = 'row';
    }
  }

  private exceedsWindowWidth(): boolean {
    const { left: leftDistance, width: elementWidth } =
      this.titleContainerTarget.getBoundingClientRect();
    const componentSize = leftDistance + elementWidth;

    return componentSize > window.innerWidth;
  }

  private isMobileScreen(): boolean {
    const screenTablet = 640;

    return window.innerWidth < screenTablet;
  }
}
