import { Controller } from 'stimulus';
import { MessageStyle } from './enums/message_style';
import { IUserRow } from './interfaces/user_row';
import axios from 'axios';
import { IPayload } from './interfaces/payload';

export default class extends Controller {
  static targets: string[] = ['table', 'organizationId', 'submitMessageLabel'];

  declare readonly tableTarget: Element;
  declare readonly organizationIdTarget: HTMLInputElement;
  declare submitMessageLabelTarget: HTMLElement;

  private tableRows: Element[];
  private organizationId: number;
  private readonly messageContainerStyle: string = 'float-right submit-message';

  connect(): void {
    this.tableRows = Array.from(
      this.tableTarget.querySelectorAll('tbody > tr')
    );
    this.organizationId = parseInt(this.organizationIdTarget.value);
  }

  public updateUsersRoles(event: Event): void {
    event.preventDefault();
    const updatableUsers: IUserRow[] = this.tableRows
      .map(this.userRowDataArray)
      .filter(this.filterUpdatableUsers);
    if (updatableUsers.length === 0) {
      this.writeSubmitResult(MessageStyle.Info, 'Everything is up to date!');
      return;
    }

    this.callUserRoleUpdateResource(updatableUsers);
  }

  private userRowDataArray(row: Element): IUserRow {
    const userActualId: number = parseInt(row.getAttribute('data-user-id'));
    const userActualRole: number = parseInt(row.getAttribute('data-user-role'));
    const userRoleInput: HTMLInputElement = row.querySelector('td > select');

    return {
      id: userActualId,
      actualRole: userActualRole,
      newRole: userRoleInput === null ? null : parseInt(userRoleInput.value),
    } as IUserRow;
  }

  private filterUpdatableUsers(userData: IUserRow): boolean {
    return (
      userData.actualRole !== userData.newRole && userData.newRole !== null
    );
  }

  private callUserRoleUpdateResource(userDataArray: IUserRow[]): void {
    const payload = this.buildPayload(userDataArray);

    axios
      .patch(
        `/organizations/${this.organizationId}/users/update_roles`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
          },
        }
      )
      .then((response) => {
        let status: string = response.data.status;
        if (status === 'ok') {
          this.updateUsersRolesOld(payload);
          this.writeSubmitResult(MessageStyle.Success, 'Changes Saved');
          this.hideSubmitResult();
        } else {
          this.writeSubmitResult(MessageStyle.Error, 'Something went wrong');
        }
      })
      .catch((_) => {
        this.writeSubmitResult(MessageStyle.Error, 'Something went wrong');
      });
  }

  private updateUsersRolesOld(payload: IPayload): void {
    payload.users.forEach(function (user) {
      document
        .querySelector(`tr[data-user-id="${user.id}"]`)
        .setAttribute('data-user-role', String(user.role));
    });
  }

  private buildPayload(userDataArray: IUserRow[]): IPayload {
    return {
      users: userDataArray.map((userData) => ({
        id: userData.id,
        role: userData.newRole,
      })),
    };
  }

  private writeSubmitResult(type: MessageStyle, message: string): void {
    this.submitMessageLabelTarget.className = `${this.messageContainerStyle} ${type}`;
    this.submitMessageLabelTarget.innerText = message;
  }

  private hideSubmitResult(): void {
    setTimeout(() => {
      this.submitMessageLabelTarget.className = `${this.messageContainerStyle} hide`;
    }, 5000);
  }
}
