
import { Controller } from 'stimulus';
import { ActionMenuModel } from './action_menu_model';

export default class extends Controller {
  static targets: string[] = [
    'selectAllCheckbox',
    'table',
    'actionsMenu',
    'tableRow'
  ];

  declare readonly selectAllCheckboxTarget: HTMLInputElement;
  declare readonly tableTarget: Element;
  declare readonly tableRowTargets: Element[];
  declare readonly actionsMenuTarget: HTMLElement;
  private tableRowCheckboxes: HTMLInputElement[];
  private actionMenu: ActionMenuModel;

  public connect() {
    this.tableRowCheckboxes = this.tableRowTargets
      .map(row => row.querySelector('input[type="checkbox"]') as HTMLInputElement)
      .filter(checkbox => checkbox !== null);
    this.assignActionToCheckboxes();
    this.actionMenu = new ActionMenuModel(this.actionsMenuTarget);
  }

  public selectAllCheckboxes(event: Event): void {
    this.switchAllCheckboxes(this.selectAllCheckboxTarget.checked);
    this.interactWithActionsMenu(this.selectAllCheckboxTarget);
  }

  private switchAllCheckboxes(value: boolean): void {
    const enabledCheckboxes = this.tableRowCheckboxes.filter(checkbox => !checkbox.disabled);
    enabledCheckboxes.forEach(checkbox => checkbox.checked = value);
  }

  private getSelectedCheckboxes(): HTMLInputElement[] {
    return this.tableRowCheckboxes.filter(checkbox => checkbox.checked);
  }

  private assignActionToCheckboxes(): void {
    this.tableRowCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('click', (_event: Event) => { this.interactWithActionsMenu(checkbox) });
    });
  }

  private interactWithActionsMenu(checkbox: HTMLInputElement): void {
    const selectedCheckboxes: HTMLInputElement[] = this.getSelectedCheckboxes();

    if (selectedCheckboxes.length > 0) {
      const targetCheckbox: HTMLInputElement = this.resolveTargetCheckbox(checkbox, selectedCheckboxes);

      this.actionMenu.changeVisibility(true);
      checkbox.parentElement.appendChild(this.actionsMenuTarget)
      this.actionMenu.updateFooter(selectedCheckboxes.length);
      this.actionMenu.updateInviteUsersLink(this.getOrganizationIdsFromCheckboxes(selectedCheckboxes));
    } else {
      this.actionMenu.changeVisibility(false);
      this.actionMenu.updateFooter(0);
      this.actionMenu.updateInviteUsersLink([]);
    }
  }

  private resolveTargetCheckbox(actionCheckbox: HTMLInputElement, selectedCheckboxes: HTMLInputElement[]): HTMLInputElement {
    if (actionCheckbox.checked) return actionCheckbox;

    return selectedCheckboxes.reverse().filter(checkbox => checkbox.checked)[0];
  }

  private getOrganizationIdsFromCheckboxes(checkboxes: HTMLInputElement[]): number[] {
    return checkboxes.map(checkbox => parseInt(checkbox.dataset.id));
  }
}
