import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ballotOptionsContainer'];

  declare ballotOptionsContainerTarget: HTMLElement;

  public toggleBallotOptions(event: Event): void {
    if (this.ballotOptionsContainerTarget.classList.contains('hide')) {
      this.ballotOptionsContainerTarget.classList.remove('hide');
    } else {
      this.ballotOptionsContainerTarget.classList.add('hide');
    }
  }
}
