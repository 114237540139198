import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['acceptedRadioButton']

  declare acceptedRadioButtonTarget: HTMLInputElement;

  connect(): void {
    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) this.redirectToReview(event);
    })
  }

  private redirectToReview(_event): void {
    const url = window.location.href;
    const accepted = this.acceptedRadioButtonTarget.checked;

    Turbo.visit(url.replace(`/edit`, ``) + `?accepted=${accepted}`, { action: "replace" });
    history.pushState(null, null, url);
  }
}
