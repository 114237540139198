import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['form']

  initialize() {
    $(this.formTarget).on('change', this.postFormToSaveChanges().bind(this))
  }

  postFormToSaveChanges() {
    return () => {
      Rails.fire(this.formTarget, 'submit')
    }
  }
}
