import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'smsCheckbox',
    'emailCheckbox',
    'letterCheckbox',
    'postcardCheckbox',
    'formElement'
  ]

  declare smsCheckboxTarget: HTMLInputElement;
  declare emailCheckboxTarget: HTMLInputElement;
  declare letterCheckboxTarget: HTMLInputElement;
  declare postcardCheckboxTarget: HTMLInputElement;
  declare formElementTarget: HTMLFormElement;

  public letter(): void {
    let noticeTypeIdentifier = 'letter';

    this.performUncheckAction('smsCheckbox');
    this.performUncheckAction('emailCheckbox');
    this.performUncheckAction('postcardCheckbox');
    this.reloadData(noticeTypeIdentifier);
  }

  public postcard(): void {
    let noticeTypeIdentifier = 'postcard';

    this.performUncheckAction('smsCheckbox');
    this.performUncheckAction('emailCheckbox');
    this.performUncheckAction('letterCheckbox');
    this.reloadData(noticeTypeIdentifier);
  }

  public email(): void {
    let noticeTypeIdentifier = 'email';

    this.performUncheckAction('smsCheckbox');
    this.performUncheckAction('postcardCheckbox');
    this.performUncheckAction('letterCheckbox');
    this.reloadData(noticeTypeIdentifier);
  }

  public sms(): void {
    let noticeTypeIdentifier = 'sms';

    this.performUncheckAction('emailCheckbox');
    this.performUncheckAction('postcardCheckbox');
    this.performUncheckAction('letterCheckbox');
    this.reloadData(noticeTypeIdentifier);
  }

  public reloadData(noticeTypeIdentifier: string): void {
    const old_url = window.location.href;
    const url = this.urlToReloadData(noticeTypeIdentifier);

    Turbo.visit(url, { action: 'replace' });
    history.pushState(null, null, old_url); // this little trick is used to prevent the url from changing after "Turbo.visit"
  }

  private performUncheckAction(targetName: string): void {
    if (this[`has${targetName}Target`]) {
      this.uncheck(this[`${targetName}Target`]!);
    }
  }

  private uncheck(checkbox: HTMLInputElement | undefined): void {
    if (checkbox) {
      checkbox.checked = false;
    }
  }

  private urlToReloadData(noticeTypeIdentifier: string): string {
    const voteId = this.formElementTarget.getAttribute('data-vote-id');
    const invitationIds = JSON.parse(this.formElementTarget.getAttribute('data-invitation-ids'));
    const url = `/votes/${voteId}/notices/on_demand`;

    const params = new URLSearchParams();
    invitationIds.forEach((id: string) => {
      params.append('invitation_ids[]', id);
    });
    params.append(`notice_type_identifier[${noticeTypeIdentifier}]`, 'true');

    return `${url}?${params.toString()}`;
  }
}
