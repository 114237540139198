import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['adminEmail', 'adminFirstName', 'adminLastName', 'adminRole', 'organizationId']

  selectRole(event) {
    this.adminRoleTarget.value = event.target.value
  }

  submit(event) {
    event.preventDefault()
    this._createAdminInvitation()
  }

  _createAdminInvitation() {
    this._cleanErrorMessages()

    Rails.ajax({
      type: "post",
      url: `/users/organizations/${this.organizationId}/invitations`,
      data: this._transformData(),
      success: (_response) => { this._refreshPage() },
      error: (response, _status, _xhr) => { this._setErrorMessages(response) }
    })
  }

  _transformData() {
    return new URLSearchParams({
      email: this.adminEmail,
      first_name: this.adminFirstName,
      last_name: this.adminLastName,
      role: this.adminRole
    }).toString()
  }

  _refreshPage() {
    window.location.reload()
  }

  _setErrorMessages(response) {
    let errorContainer = document.querySelector('#invitation-form-error')
    let errorMessages = Object.entries(response).map(([key, value]) => {
      return `<li>${key}: ${value.join(', ')}</li>`;
    }).join('');

    errorContainer.classList.remove('hide')
    errorContainer.querySelector('ul').innerHTML = errorMessages
  }

  _cleanErrorMessages() {
    let errorContainer = document.querySelector('#invitation-form-error')
    errorContainer.classList.add('hide')
  }

  get adminEmail() {
    return this.adminEmailTarget.value
  }

  get adminFirstName() {
    return this.adminFirstNameTarget.value
  }

  get adminLastName() {
    return this.adminLastNameTarget.value
  }

  get adminRole() {
    return this.adminRoleTarget.value
  }

  get organizationId() {
    return this.organizationIdTarget.value
  }
}
