import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [
    "leftListSelectedItemsInput",
    "rightListSelectedItemsInput",
    "selectAllButton",
    "leftList",
    "rightList",
    "itemsSelectedTextLeft",
    "itemsSelectedTextRight",
    "selectAllButtonLeft",
    "selectAllButtonRight",
    "moveButtonLeft",
    "moveButtonRight",
    "leftSearchBar",
    "rightSearchBar",
    "leftClearButton",
    "rightClearButton"
  ];
  private leftListItemCount: number = 0;
  private rightListItemCount: number = 0;
  private leftListSelectedItems: string[] = []
  private rightListSelectedItems: string[] = []
  private observer: MutationObserver;

  public connect() {
    this.loadInfo();
    this.updateItemsSelectedText();
    this.addListenersToSearchBar();
    this.checkClearButtonVisibility(this.leftSearchBarTarget, this.leftClearButtonTarget);
    this.checkClearButtonVisibility(this.rightSearchBarTarget, this.rightClearButtonTarget);
  }

  public onMoveButtonClick(event: Event) {
    const moveDirection = event.currentTarget.dataset.movedirection;
    if (moveDirection === 'left') {
      this.leftListSelectedItems = [];
    } else {
      this.rightListSelectedItems = [];
    }
    this.updateInfo();
  }

  public onItemClick(event: Event) {
    const item = event.currentTarget as HTMLElement;
    const value = item.dataset.value;
    const onLeftList = item.dataset.list === 'left';
    const alreadyClicked = this.alreadyClicked(value, onLeftList);
    if (alreadyClicked) {
      this.unselectItem(item, onLeftList);
    } else {
      this.selectItem(item, onLeftList);
    }
    this.updateInfo();
  }

  public onSelectAllButtonClick(event: Event) {
    const button = event.currentTarget as HTMLElement;
    const onLeftList = button.dataset.list === 'left';
    const listTarget = onLeftList ? this.leftListTarget : this.rightListTarget;
    const items = listTarget.querySelectorAll('li');
    const allItemsSelected = this.allItemsSelected(onLeftList ? 'left' : 'right');
    items.forEach((item) => {
      const value = item.dataset.value;
      if (allItemsSelected) {
        this.unselectItem(item, onLeftList);
      } else {
        const alreadyClicked = this.alreadyClicked(value, onLeftList);
        if (!alreadyClicked) {
          this.selectItem(item, onLeftList);
        }
      }
    })

    this.updateInfo();
  }

  private addListenersToSearchBar() {
    this.leftSearchBarTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onSearchInputChange(event);
      }
    });
    this.leftSearchBarTarget.addEventListener('focus', () => {
      this.addMutationObserverToTextField(this.leftSearchBarTarget.id);
    });
    this.leftSearchBarTarget.addEventListener('input', (event: Event) => {
      this.checkClearButtonVisibility(this.leftSearchBarTarget, this.leftClearButtonTarget);
    });
    this.leftClearButtonTarget.addEventListener('click', () => {
      this.leftSearchBarTarget.value = '';
      this.checkClearButtonVisibility(this.leftSearchBarTarget, this.leftClearButtonTarget);
    });

    this.rightSearchBarTarget.addEventListener('keydown', (event: Event) => {
      if (event.key === 'Enter') {
        this.onSearchInputChange(event);
      }
    });
    this.rightSearchBarTarget.addEventListener('focus', () => {
      this.addMutationObserverToTextField(this.rightSearchBarTarget.id);
    });
    this.rightSearchBarTarget.addEventListener('input', (event: Event) => {
      this.checkClearButtonVisibility(this.rightSearchBarTarget, this.rightClearButtonTarget);
    });
    this.rightClearButtonTarget.addEventListener('click', () => {
      this.rightSearchBarTarget.value = '';
      this.checkClearButtonVisibility(this.rightSearchBarTarget, this.rightClearButtonTarget);
    });
  }

  private checkClearButtonVisibility(searchBar: HTMLInputElement, clearButton: HTMLElement) {
    clearButton.style.display = searchBar.value.length > 0 ? 'block' : 'none';
  }

  private onSearchInputChange(event: Event) {
    this.leftListSelectedItems = [];
    this.rightListSelectedItems = [];
    this.updateInfo();
  }

  private alreadyClicked(value: string, onLeftList: boolean) {
    if (onLeftList) {
      return this.leftListSelectedItems.includes(value);
    }
    return this.rightListSelectedItems.includes(value);
  }

  private selectItem(item: HTMLElement, onLeftList: boolean) {
    item.classList.add('selected');
    if (onLeftList) {
      this.leftListSelectedItems.push(item.dataset.value);
    } else {
      this.rightListSelectedItems.push(item.dataset.value);
    }
  }

  private unselectItem(item: HTMLElement, onLeftList: boolean) {
    item.classList.remove('selected');
    if (onLeftList) {
      this.leftListSelectedItems = this.leftListSelectedItems.filter((value) => value !== item.dataset.value);
    } else {
      this.rightListSelectedItems = this.rightListSelectedItems.filter((value) => value !== item.dataset.value);
    }
  }

  private updateHiddenFields() {
    this.leftListSelectedItemsInputTarget.value = this.leftListSelectedItems.join(',');
    this.rightListSelectedItemsInputTarget.value = this.rightListSelectedItems.join(',');
  }

  private updateInfo() {
    this.updateItemsSelectedText();
    this.updateHiddenFields();
    this.checkToggleAllButtonText();
    this.checkMoveButtonDisabledStatus();
  }

  private checkMoveButtonDisabledStatus() {
    if (this.leftListSelectedItems.length === 0) {
      this.moveButtonLeftTarget.setAttribute('disabled', 'disabled');
    } else {
      this.moveButtonLeftTarget.removeAttribute('disabled');
    }

    if (this.rightListSelectedItems.length === 0) {
      this.moveButtonRightTarget.setAttribute('disabled', 'disabled');
    } else {
      this.moveButtonRightTarget.removeAttribute('disabled');
    }
  }

  private loadInfo() {
    this.leftListItemCount = this.leftListTarget.querySelectorAll('li').length;
    this.rightListItemCount = this.rightListTarget.querySelectorAll('li').length;
  }

  private updateItemsSelectedText() {
    this.itemsSelectedTextLeftTarget.innerText = `${this.leftListSelectedItems.length}`;
    this.itemsSelectedTextRightTarget.innerText = `${this.rightListSelectedItems.length}`;
  }

  private checkToggleAllButtonText() {
    if (this.allItemsSelected('left') && this.leftListItemCount > 0) {
      this.selectAllButtonLeftTarget.innerText = 'Deselect All';
    } else {
      this.selectAllButtonLeftTarget.innerText = 'Select All';
    }
    if (this.allItemsSelected("right") && this.rightListItemCount > 0) {
      this.selectAllButtonRightTarget.innerText = 'Deselect All';
    } else {
      this.selectAllButtonRightTarget.innerText = 'Select All';
    }
  }

  private allItemsSelected(position: string) {
    if (position === 'left') {
      return this.leftListSelectedItems.length === this.leftListItemCount;
    }
    return this.rightListSelectedItems.length === this.rightListItemCount;
  }

  private addMutationObserverToTextField(textFieldId: string) {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.observer = new MutationObserver((mutations) => {
      const textField = document.getElementById(textFieldId);
      const textLength = textField.value.length;
      textField.focus()
      textField.setSelectionRange(textLength, textLength);
    });
    this.observer.observe(document.getElementById("organizations--results--box-filtering-component"), {childList: true});
  }
}
