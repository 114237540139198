import { Controller } from 'stimulus'
import $ from 'jquery'
import NProgress from 'nprogress'

export default class extends Controller {
  static targets = ['switch']

  connect() {
    this.syncSwitch()
  }

  load(value) {
    // This should be replaced with fetch API once we're not using jquery
    // anymore
    $.get(this.data.get('url'), { weight: value }, (response) => {
      this.element.innerHTML = response
      this.checked = value
      this.syncSwitch()
      NProgress.done()
    })
  }

  toggle(event) {
    NProgress.start()
    this.load(event.target.checked)
  }

  get checked() {
    return this.data.get('checked') === 'true'
  }

  set checked(value) {
    this.data.set('checked', value ? 'true' : 'false')
    this.syncSwitch()
  }

  syncSwitch() {
    this.switchTarget.checked = this.checked
  }
}
