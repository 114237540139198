import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['examples_area', 'formElement'];

  declare examplesAreaTarget: HTMLElement;

  declare formElementTarget: HTMLFormElement;

  declare showExamplesIsOn: Boolean;

  declare clickListener: any;

  submitForm(event) {
    event.preventDefault();
    return false;
  }

  formClick(event) {
    const { target } = event;
    const { classList } = target;
    if (classList.contains('example')) {
      this.insertExampleIntoField(target);
      event.preventDefault();
      return true;
    }
    if (classList.contains('show_more_examples')) {
      this.revealAdditionalExamples(target);
    }
  }

  insertExampleIntoField(exampleTagElement) {
    const inputElement = exampleTagElement
      .closest('.fieldset')
      .querySelector('input, textarea');

    if (inputElement.tagName === 'INPUT') {
      const valueToInsert =
        exampleTagElement.dataset.value || exampleTagElement.textContent;
      const newInputValue = [inputElement.value, valueToInsert]
        .filter((x) => x)
        .join(' ');
      inputElement.value = newInputValue;
      return;
    }
    if (inputElement.tagName === 'TEXTAREA') {
      const newValue = exampleTagElement.dataset.values
        .split(',')
        .filter((x) => x)
        .join('\\\n')
        .replace(/\\/g, '');

      inputElement.value = newValue;
      return;
    }
  }

  revealAdditionalExamples(target) {
    target
      .closest('.fieldset')
      .querySelectorAll('.example')
      .forEach((i) => {
        i.removeAttribute('hidden');
      });
    target.remove();
  }
}
