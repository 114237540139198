import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectInput']

  declare selectInputTarget: HTMLInputElement;

  initialize(): void {
    let voteId = this.selectInputTarget.getAttribute('data-vote-id');
    let voterListSource = this.selectInputTarget.getAttribute('data-voters-source')
    let invitationKey = this.selectInputTarget.getAttribute('data-invitation-key')
    const setupOptions = {
      candidate_names: this.setupSelectInput,
      sample_candidate_names: this.setupSampleSelectInput
    }
    setupOptions[voterListSource](this.selectInputTarget, voteId, invitationKey)
  }

  setupSelectInput(input, voteId, invitationKey): void {
    let url = `/votes/${voteId}/voters/proxy_candidates`
    $(input).select2({
      width: 450,
      allowClear: true,
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            search_term: params.term,
            page: params.page || 1,
            answer_limit: 10,
            per_page: 10,
            invitation_key: invitationKey
          };
        },
        processResults: (data, params) => {
          data.results.map(i => (i.id = i.text))
          params.page = params.page || 1
          params.per_page = params.per_page || 10

          return {
            results: data.results,
            pagination: {
              more: (params.page * params.per_page) < data.meta.total
            }
          }
        }
      }
    })
  }

  setupSampleSelectInput(input, voteId, invitationKey): void {
    let url = `/votes/${voteId}/voters/sample_invitations`
    const data = {
      answer_limit: 10,
      invitation_key: invitationKey
    }

    Rails.ajax({
      url: url,
      type: 'get',
      data: new URLSearchParams(Object.keys(data).map(key=>[key,data[key]])),
      success: (data) => {
        $(input).select2({
          allowClear: true,
          width: 450,
          data: data.results
        })
      }
    });
  }
}
