import Answer from './answer'

export default class BaseQuestion {
  constructor(id, min, max) {
    this.id = id
    this.min = min
    this.max = max
    this.answers = this.getAnswers()
  }

  getAnswers() {
    const nodeList = document.querySelectorAll(this.answerListSelector)
    const elementsArray = [...nodeList]

    return elementsArray
      .filter((element) => !element.id.endsWith('_abstain'))
      .map((element) => new Answer(element.id, this.responseToAnswer(element)))
  }

  checkIfAbstained() {
    const abstainElement = document.querySelector(this.abstentionSelector)
    return abstainElement ? abstainElement.checked : false
  }

  get hasSelections() {
    return this.answered > 0
  }

  get answered() {
    return this.answers.filter((answer) => answer.answered).length
  }

  get abstentionSelector() {
    return [this.singleAbstainSelector, this.multipleAbstainSelector].join(',')
  }

  get singleAbstainSelector () {
    return `#question_${this.id} input[type=radio]#ballot_data_questions_${this.id}_answer_abstain`
  }

  get multipleAbstainSelector () {
    return `#question_${this.id} input[type=checkbox]#ballot_data_questions_${this.id}_answers_abstain`
  }

  outOfRange() {
    return this.answered < this.min || this.answered > this.max
  }

  get answerListSelectorToListenTo() {
    return this.answerListSelector
  }
}
