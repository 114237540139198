import { Controller } from 'stimulus';

export default class extends Controller {
    static values = { initialCount: Number }
    static targets = ["itemsList"]

    declare readonly itemsListTarget: HTMLElement;

    expand(event: Event): void {
        this.itemsListTarget.querySelectorAll('.hidden').forEach((el: Element) => {
            el.classList.remove('hidden');
        });

        if (event.target instanceof HTMLElement) {
            event.target.remove();
        }
    }
}
