import { Controller } from 'stimulus';
import { joinUnlessMissingOrSame } from "utils/string_joins"

export default class extends Controller {
  connect() {
    // We do the joining here in JS because the Two Factor Auth label is shown
    // in real time when editing it, so this join function is already in the JS.
    // Having it only in JS helps to prevent the preview and real labels getting
    // out of sync after further edits.
    this.scope.element.innerText = joinUnlessMissingOrSame(
      this.data.get('prefix'),
      this.data.get('suffix')
    )
  }
}
