import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'proxyDefinitionOptions',
    'proxyDefinitionCheckbox',
    'defaultRadio',
    'administratorRadio',
    'administratorOnlyRadio'
  ]

  declare proxyDefinitionOptionsTarget: HTMLInputElement;
  declare proxyDefinitionCheckboxTarget: HTMLInputElement;
  declare defaultRadioTarget: HTMLInputElement;
  declare administratorRadioTarget: HTMLInputElement;
  declare administratorOnlyRadioTarget: HTMLInputElement;

  public enableProxyDefinitionOptions(): void {
    if(this.proxyDefinitionCheckboxTarget.checked){
      this.proxyDefinitionOptionsTarget.classList.remove('hide');
      this.checkDefaultRadioInput();
    }
    else{
      this.proxyDefinitionOptionsTarget.classList.add('hide');
      this.defaultRadioTarget.checked = true
    }
  }

  private checkDefaultRadioInput(): void {
    if (this.administratorRadioTarget.disabled) {
      this.administratorOnlyRadioTarget.checked = true;
    } else {
      this.administratorRadioTarget.checked = true;
    }
  }
}
