import { Controller } from 'stimulus'
import $ from 'jquery'

const setToClipboard = async (blob) => {
  const data = [new ClipboardItem({ [blob.type]: blob })]
  await navigator.clipboard.write(data)
}

export default class extends Controller {
  static targets = ['qrImageContainer', 'qrButton', 'qrImageTag']

  initialize() {
    this.originalQrButtonContent = this.qrButtonTarget.innerHTML

    // need to store the reference to the element because it gets moved outside
    // of the component element when jQuery puts it in a modal. Next time you try
    // to access it with `this.qrImageTarget` the element is not found.
    this.qrContainerElement = this.qrImageContainerTarget
  }

  qrButtonClicked(event) {
    if (event.target.innerText.match(/copy/i)) {
      this.copyQrImageToClipBoard(this.qrImageTagTarget, this)
    } else {
      this.openModal()
    }
  }

  async copyQrImageToClipBoard(sourceImageTag, componentController) {
    const response = await fetch(sourceImageTag.getAttribute('src'))
    const blob = await response.blob()
    await setToClipboard(blob)
    componentController.qrButtonTarget.innerHTML = 'Copied!'
    setTimeout(() => {
      componentController.qrButtonTarget.innerHTML = componentController.originalQrButtonContent
    }, 1000)
  }

  openModal() {
    $(this.qrContainerElement).modal()
  }
}
