import { Controller } from 'stimulus'

export default class extends Controller {
  public connect(): void {
    const closeModalButton = document.querySelector('.turbo-modal-component__close-modal');
console.log('fechei');
    if (closeModalButton) {
      closeModalButton.addEventListener('click', () => {
        window.location.reload();
      });
    }
  }
}
