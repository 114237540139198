import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'orangeCheckbox',
    'pickColors',
    'customizeColorsCheckbox',
    'buttonColor',
    'textColor'
  ];

  showColorPicker() {
    if (this.customizeColorsCheckboxTarget.checked) {
      this.pickColorsTarget.classList.remove('hide');
    } else {
      this.pickColorsTarget.classList.add('hide');
      this.orangeCheckboxTarget.checked = true;
      this.buttonColorTarget.className = 'btn btn--orange';
      this.textColorTarget.className = 'orange-text';
    }
  }

  showOrange() {
    this.buttonColorTarget.className = 'btn btn--orange';
    this.textColorTarget.className = 'orange-text';
  }

  showBlue() {
    this.buttonColorTarget.className = 'btn btn--dark-blue';
    this.textColorTarget.className = 'dark-blue-text';
  }

  showGreen() {
    this.buttonColorTarget.className = 'btn btn--green';
    this.textColorTarget.className = 'green-text';
  }
}
