import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'proxyVoteToMemberRadioButton',
    'proxyVoteToAdminRadioButton',
    'proxyVoteToMemberWithoutAcceptanceRadioButton',
  ]

  declare proxyVoteToMemberRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToAdminRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToMemberWithoutAcceptanceRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToMemberSelectorComponent: HTMLElement;

  connect(): void {
    this.proxyVoteToMemberSelectorComponent = document.querySelector('.ballots-proxy-voter-component')!

    if (this.proxyToMemberSelected())
      this.enableProxyVoteToMember();

    if (this.proxyToMemberWithoutAcceptanceSelected())
      this.enableProxyVoteToMemberWithoutAcceptance();

    if (this.proxyToAdminSelected())
      this.enableProxyVoteToAdmin();

    this.confirmSubmit();
    this.reloadLocationAfterSubmit();
  }

  public enableProxyVoteToMember(): void {
    this.enableProxyVoteToMemberSelector();
    this.defineSkipAcceptanceValue(false);
  }

  public enableProxyVoteToMemberWithoutAcceptance(): void {
    this.enableProxyVoteToMemberSelector();
    this.defineSkipAcceptanceValue(true);
  }

  public enableProxyVoteToAdmin(): void {
    this.disableProxyVoteToMemberSelector();
    this.defineSkipAcceptanceValue(false);
  }

  private disableProxyVoteToMemberSelector(): void {
    this.proxyVoteToMemberSelectorComponent.classList.add('hide')
  }

  private enableProxyVoteToMemberSelector(): void {
    this.proxyVoteToMemberSelectorComponent.classList.remove('hide')
  }

  private reloadLocationAfterSubmit(): void {
    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) location.reload();
    })
  }

  private confirmSubmit(): void {
    const submitButton = document.querySelector('form .submit-button');

    submitButton?.addEventListener('click', (event) => {
      const confirmed = confirm('Are you sure you want to transfer the voting rights?');
      if (!confirmed) event.preventDefault();
    });
  }

  private defineSkipAcceptanceValue(value: boolean) {
    const input = document.getElementById('skip_acceptance') as HTMLInputElement;

    input.value = String(value)
  }

  private proxyToMemberSelected(): boolean {
    document.querySelector('#vote_mode_proxy_vote_to_member')
      && this.proxyVoteToMemberRadioButtonTarget.checked
  }

  private proxyToMemberWithoutAcceptanceSelected(): boolean {
    document.querySelector('#vote_mode_proxy_vote_to_member_without_acceptance')
      && this.proxyVoteToMemberWithoutAcceptanceRadioButtonTarget.checked
  }

  private proxyToAdminSelected(): boolean {
    document.querySelector('#vote_mode_proxy_vote_to_admin')
      && this.proxyVoteToAdminRadioButtonTarget.checked
  }
}
