import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'submitButton']

  passwordChange(_event) {
    this._toggleSubmitButton(!this._passwordsFilled())
  }

  _passwordsFilled() {
    return this.password.length > 0 && this.passwordConfirmation.length > 0
  }

  _toggleSubmitButton(toggleResult) {
    this.submitButtonTarget.disabled = toggleResult
  }

  get password() {
    return this.passwordTarget.value
  }

  get passwordConfirmation() {
    return this.passwordConfirmationTarget.value
  }
}
