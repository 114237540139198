import { Controller } from 'stimulus'

export default class extends Controller {
  static targets: string[] = [
    'container',
    'form',
    'skipPersistenceInput'
  ]

  declare readonly containerTarget: HTMLDivElement
  declare readonly formTarget: HTMLFormElement
  declare readonly skipPersistenceInputTarget: HTMLInputElement

  connect(): void {
    this.element.addEventListener("turbo:submit-end", event => NProgress.done())
  }

  updateOverview(event): void {
    NProgress.start()
    this.skipPersistenceInputTarget.value = 'true'
    this.formTarget.requestSubmit()
  }

  triggerSaveSettings(event): void {
    NProgress.start()
    event.preventDefault()
    this.formTarget.requestSubmit()
  }

  triggerToggleSettings(event): void {
    if (event.detail.showHideStatus === 'show') {
      this.containerTarget.classList.remove('hidden')
    } else {
      this.containerTarget.classList.add('hidden')
    }
  }

  handleVoterViewResultsChange(event): void {
    if (event.detail.fromModal) {
      this.element.querySelector(`input[name="results_viewable_for_voter"][value="${event.detail.voterViewResultsType}"]`).click()
    } else {
      this.updateOverview()
    }
  }
}