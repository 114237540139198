import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'operationalTimerDurationMinutesInput',
    'operationalTimerDurationSecondsInput',
    'virtualTimerDurationMinutesInput',
    'virtualTimerDurationSecondsInput',
    'timerOptions',
    'calendarOptions',
    'timerRadioButton',
    'calendarRangeRadioButton',
    'endDateInput',
    'startDateInput'
  ];

  declare operationalTimerDurationMinutesInputTarget: HTMLInputElement;
  declare operationalTimerDurationSecondsInputTarget: HTMLInputElement;
  declare virtualTimerDurationMinutesInputTarget: HTMLInputElement;
  declare virtualTimerDurationSecondsInputTarget: HTMLInputElement;
  declare timerOptionsTarget: HTMLInputElement;
  declare calendarOptionsTarget: HTMLInputElement;
  declare timerRadioButtonTarget: HTMLInputElement;
  declare calendarRangeRadioButtonTarget: HTMLInputElement;
  declare endDateInputTarget: HTMLInputElement;
  declare startDateInputTarget: HTMLInputElement;

  connect(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const vote_type = urlParams.get('vote_type')

    if (document.querySelectorAll('.elections.new').length) {
      if (vote_type === 'motion') {
        this.timerRadioButtonTarget.click();
      } else {
        this.calendarRangeRadioButtonTarget.click();
      }
    }

    EB.Datepicker.initDatePickers(new Date());
  }

  public enableTimerOptions(): void {
    this.operationalTimerDurationMinutesInputTarget.disabled = false;
    this.operationalTimerDurationSecondsInputTarget.disabled = false;
    this.timerOptionsTarget.classList.remove('hide');
    this.calendarOptionsTarget.classList.add('hide');
  }

  public enableCalendarOptions(): void {
    this.calendarOptionsTarget.classList.remove('hide');
    this.timerOptionsTarget.classList.add('hide');
    this.operationalTimerDurationMinutesInputTarget.disabled = true;
    this.operationalTimerDurationMinutesInputTarget.value = '03';
    this.operationalTimerDurationSecondsInputTarget.disabled = true;
    this.operationalTimerDurationSecondsInputTarget.value = '00';
    this.virtualTimerDurationMinutesInputTarget.value = '03'
    this.virtualTimerDurationSecondsInputTarget.value = '00'
  }

  public toggleVirtualTimerOptions(): void {
    this.virtualTimerDurationMinutesInputTarget.toggleAttribute('disabled');
    this.virtualTimerDurationSecondsInputTarget.toggleAttribute('disabled');
  }

  public textIntoStartDateField(event) {
    event.preventDefault();
    this.startDateInputTarget.value = event.target.textContent
    this.startDateInputTarget.focus()
  }

  public textIntoEndDateField(event) {
    event.preventDefault();
    this.endDateInputTarget.value = event.target.textContent
    this.endDateInputTarget.focus()
  }
}
