import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["returnCheckbox", "remitCheckbox", 'electionIdField', 'downloadElectionDataLink', 'csvFilename', 'useElectionField', 'editElectionLink']

  declare returnCheckboxTarget: HTMLInputElement;
  declare remitCheckboxTarget: HTMLInputElement;
  declare electionIdFieldTarget: HTMLInputElement;
  declare csvFilenameTarget: HTMLSpanElement;
  declare useElectionFieldTarget: HTMLInputElement;
  declare downloadElectionDataLinkTarget: HTMLAnchorElement;
  declare editElectionLinkTarget: HTMLAnchorElement;

  public connect(): void {

    if (this.returnCheckboxTarget.checked) {
      document.getElementById("returnAddressFields").classList.remove("hidden")
    }
    if (this.remitCheckboxTarget.checked) {
      document.getElementById("remitAddressFields").classList.remove("hidden")
    }

    this.electionIdFieldTarget.addEventListener('change', (event) => {
      this.updateDownloadLink()
    })
    this.downloadElectionDataLinkTarget.addEventListener('click', (event) => {
      if (!this.electionIdFieldTarget.value) {
        event.preventDefault()
        alert('Please select an election')
      } else {
       this.csvFilenameTarget.innerHTML = `election_${this.electionIdFieldTarget.value}.csv`
       this.useElectionFieldTarget.value = String(true)
      }
    })
    this.updateDownloadLink()
  }

  public updateDownloadLink(): void {
    const electionId = this.electionIdFieldTarget.value
    this.downloadElectionDataLinkTarget.href = `/admin/postal_batches/${this.data.get('postal-batch-id-value')}/elections/${electionId}/csv_download`
    this.editElectionLinkTarget.href = `/admin/elections/${electionId}/edit`
  }

  public toggleReturnAddressFields(event: Event): void {
    const target = event.target as HTMLInputElement
    const returnAddressFields = document.getElementById("returnAddressFields")
    if (target.checked) {
      returnAddressFields.classList.remove("hidden")
    } else {
      returnAddressFields.classList.add("hidden")
    }
  }

  public toggleRemitAddressFields(event: Event): void {
    const target = event.target as HTMLInputElement
    const remitAddressFields = document.getElementById("remitAddressFields")
    if (target.checked) {
      remitAddressFields.classList.remove("hidden")
    } else {
      remitAddressFields.classList.add("hidden")
    }
  }
}

