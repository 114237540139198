import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dirtyInput']

  declare readonly dirtyInputTarget: HTMLInputElement

  handleSaveVoterResultsSettings(event): void {
    this.dirtyInputTarget.value = 'false'
  }

  loadReport(event): void {
    if (this.dirtyInputTarget.value === 'true') {
      event.preventDefault()
      alert('Please save your changes first before accessing the report')
    }
  }
}