import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = ['clipboardButton', 'uriHiddenInput'];

  declare clipboardButtonTarget: HTMLButtonElement;
  declare uriHiddenInputTarget: HTMLInputElement;

  public clickClipboardButton(event: Event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.uriHiddenInputTarget.value);
    this.clipboardButtonTarget.innerText = 'Copied!';

    setTimeout(() => { this.clipboardButtonTarget.innerText = 'Copy Code'; }, 3000);
  }
}
