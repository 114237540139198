import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryContainer', 'countrySelect', 'countryText'];

  declare countryContainerTarget: HTMLElement;
  declare countrySelectTarget: HTMLSelectElement;
  declare countryTextTarget: HTMLInputElement;

  public connect(): void {
    this.changeCountrySelection();
  }

  public changeCountrySelection() {
    const selectedValue = this.countrySelectTarget.value;

    if (selectedValue === 'other') {
      this.countryContainerTarget.classList.remove('hide');
      this.countryTextTarget.setAttribute('name', 'return_address[country]');
    } else {
      this.countryContainerTarget.classList.add('hide');
      this.countryTextTarget.removeAttribute('name');
    }
  }
}
