import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = ["dialog"]

  declare readonly dialogTarget: HTMLDialogElement

  acceptedValue: string | null;
  voteIdValue: string | null;
  csrfTokenValue: string | null;
  optionValue: string | null;
  weightedValue: string | null;
  hightIntegrityVoteValue: string | null;

  connect() {
    this.acceptedValue = this.data.get("accepted");
    this.voteIdValue = this.data.get("vote-id");
    this.csrfTokenValue = this.data.get("csrf-token");
    this.hightIntegrityVoteValue = this.data.get("high-integrity-vote");
  }

  handleClick(event: Event) {
    const target = event.target as HTMLElement;
    this.optionValue = target.dataset.option ?? null;
    this.weightedValue = target.dataset.weighted ?? null;

    if (this.hightIntegrityVoteValue === 'true' && this.acceptedValue !== 'true') {
      event.preventDefault()
      $('body').append(this.modal())
      $('#export-result-modal').modal()
    }
  }

  modal() {
    return `
    <div id="export-result-modal" class="modal">
      <h3 class="template_error">Warning</h3>
      <p>If 1 or more of your Reporting Groups have 1 vote, you can potentially link these voters to their voting choices. Do you want continue and view Reporting Group results?</p>
      <div class="actions">
        <form action="/elections/${this.voteIdValue}/reports/results/acceptances" method="post">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="vote_id" value="${this.voteIdValue}" />
          <input type="hidden" name="option" value="${this.optionValue}" />
          <input type="hidden" name="weighted" value="${this.weightedValue}" />
          <input type="hidden" name="authenticity_token" value="${this.csrfTokenValue}" />
          <button type="submit" class="btn btn--orange">Continue</button>
        </form>
        <a class="btn btn--orange" rel="modal:close">Cancel</a>
      </div>
    </div>
    `
  }
}
