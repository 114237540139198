import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'endDateInput',
    'startDateInput'
  ];

  declare endDateInputTarget: HTMLInputElement;
  declare startDateInputTarget: HTMLInputElement;

  connect(): void {
    EB.Datepicker.initDatePickers(new Date());

    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        this.reload_location(event);
      }
    })
  }

  public textIntoStartDateField(event) {
    event.preventDefault();
    this.startDateInputTarget.value = event.target.textContent
    this.startDateInputTarget.focus()
  }

  public textIntoEndDateField(event) {
    event.preventDefault();
    this.endDateInputTarget.value = event.target.textContent
    this.endDateInputTarget.focus()
  }

  private reload_location(_event) {
    location.reload();
  }
}
