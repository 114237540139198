import { Controller } from 'stimulus'

export default class extends Controller {
  private addressInputComponent: HTMLElement;

  constructor(element: HTMLElement) {
    super(element);

    this.addressInputComponent = document.querySelector('.address-input-component') as HTMLElement;
  }

  public noEnvelope(): void {
    this.toggleInputComponents(false, true);
  }

  public single(): void {
    this.toggleInputComponents(false, true);
  }

  public double(): void {}

  private toggleInputComponents(showDoubleEnvelope: boolean, showAddress: boolean): void {
    this.addressInputComponent.classList.toggle('hide', !showAddress);
  }

  private shouldHideInputComponent(): void{
    const postcardCheckbox = document.querySelector('#postcard_notice_selection > input[type=checkbox]') as HTMLInputElement;
    const customAddress = document.querySelector('#return_address_double_envelope_custom') as HTMLInputElement;

    return postcardCheckbox.checked || customAddress.checked;
  }
}
