import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  initialize() {
    let questions = document.getElementById('questions');
    let addQuestionButtonElement = document.getElementById('add_question_button')

    this.addQuestionButtonJqueryObject = $(addQuestionButtonElement)
    addQuestionButtonElement.addEventListener('click', this.openAddQuestionModal.bind(this))

    if (questions.children.length > 0) {
      return
    }

    this.openAddQuestionModal()

  }

  openAddQuestionModal(event) {
    if (event) event.preventDefault();

    this.addQuestionButtonJqueryObject.modal({ clickClose: false })
  }
}
