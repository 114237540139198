import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['component']

  connect() {
    let tableController = this.componentTarget.getAttribute('data-table-controller');
    document.querySelectorAll('.voter_list_pagination_component a').forEach((link) => {
      link.setAttribute('data-remote', true);
      link.setAttribute('data-type', 'html');
      link.setAttribute('data-action', `ajax:success->${tableController}#refresh`)
    })
  }

}
