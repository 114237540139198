import { Controller } from 'stimulus'
import { Elections } from 'electionbuddy'

export default class extends Controller {
  connect() {
    if (Elections.Results.pageRequiresDragAndDropFeature(document.body)) {
      Elections.Results.initWriteInDrag(this.element)
    }
  }
}
