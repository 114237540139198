import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["readOnlyDiv", "editDiv"]

  declare readOnlyDivTarget: HTMLDivElement
  declare editDivTarget: HTMLDivElement

  triggerFormSubmit(event: Event) {
      const btn = event.target as HTMLInputElement
      const form = btn.closest("form") as HTMLFormElement
      if (form) {
        form.requestSubmit()
      }
  }

  toggleEditable(event: Event) {
    this.readOnlyDivTarget.classList.toggle("hidden")
    this.editDivTarget.classList.toggle("hidden")
  }
}
