import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'emailContainer',
    'smsContainer',
    'letterContainer',
    'postcardContainer',
    'printedContainer',
    'manualContainer',
    'letterCheckbox',
    'postcardCheckbox'
  ];

  private doubleEnvelopeInputComponent: HTMLElement;
  private addressInputComponent: HTMLElement;
  private _letterCheckboxTarget: HTMLInputElement;
  private _postcardCheckboxTarget: HTMLInputElement;

  constructor(element: HTMLElement) {
    super(element);

    this.doubleEnvelopeInputComponent = document.querySelector('.double-envelope-input-component') as HTMLElement;
    this.addressInputComponent = document.querySelector('.address-input-component') as HTMLElement;
  }

  public toggleOptions(event: Event): void {
    const noticeIdentifier = event.target.getAttribute('data-notice-identifier');

    this.slideContainer(this.container(noticeIdentifier));
    this.toggleIcon(noticeIdentifier);

    if (noticeIdentifier === 'postcard' || noticeIdentifier === 'letter')
      this.openAddressInput();

    if (noticeIdentifier === 'letter')
      this.openDoubleEnvelopeInput();
  }

  private container(identifier): HTMLElement {
    return this.targets.find(`${identifier}Container`) as HTMLElement;
  }

  private slideContainer(container: HTMLElement): void {
    // try to make slideUp and slideDown effects
    if (container.classList.contains('hide')) {
      container.classList.remove('hide');
    } else {
      container.classList.add('hide');
    }
  }

  private toggleIcon(identifier): void {
    const outterContainer = document.querySelector(`#${identifier}_notice_selection`) as HTMLElement;

    if (outterContainer.classList.contains('active_setting')) {
      outterContainer.classList.remove('active_setting');
    } else {
      outterContainer.classList.add('active_setting');
    }
  }

  private openAddressInput(): void {
    if (this.postcardCheckboxTarget.checked || this.letterCheckboxTarget.checked) {
      this.addressInputComponent.classList.remove('hide');
    }
  }
}
