import { Controller } from 'stimulus'
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ['questionSelect']

  declare questionSelectTarget: HTMLSelectElement;

  public searchAnswers(_event) {
    const currentUrl = window.location.href;
    const questionId = this.questionSelectTarget.value;
    const voteId = this.questionSelectTarget.dataset.voteId;
    const url = `/votes/${voteId}/choice/search_answers?question_id=${questionId}`;

    Turbo.visit(url);
    history.pushState(null, null, currentUrl);
  }
}
