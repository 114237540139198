import { mapValues } from 'lodash';
import { BaseController } from '../../base_controller';
import { EVENTS as QUESTION_EVENTS } from '../question_component/question_component_controller';
import { EVENTS as KEY_OVERLAY_EVENTS } from '../key_overlay_component/key_overlay_component_controller';
import {
  UNIQUE_ID as HEADER_UNIQUE_ID,
  EVENTS as HEADER_EVENTS,
} from '../header_component/header_component_controller';
import { letterToIndex } from '../../utils/string';

const UNIQUE_ID = 'ballots--form-component';

export const EVENTS = (() => {
  const events = {
    FOCUS_QUESTION_BY_INDEX: 'focus_question_by_index',
    FOCUS_QUESTION_BY_LETTER: 'focus_question_by_letter',
    NUMBER_PRESSED: 'number_pressed',
    NUMBER_CLEARED: 'number_cleared',
    CLEAR_FOCUS: 'clear_focus',
    CHECK_ANSWER_BY_NUMBER: 'check_answer_by_number',
    FOCUS_HEADER: 'focus_header',
    UNFOCUS_HEADER: 'unfocus_header',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = ['submitButton'];

  declare submitButtonTarget: HTMLButtonElement;
  declare hasSubmitButtonTarget: boolean;

  static values = {
    questionsCount: Number,
    advancedKeybindingActive: Boolean,
    keyOverlayIdentifier: String,
  };

  declare focusedQuestionIndex: number;
  declare questionsCountValue: number;
  declare advancedKeybindingActiveValue: boolean;
  declare keyOverlayIdentifierValue: string;

  declare pressedNumbers: number[];
  declare pressedLetters: string[];
  declare answerInputIsFocused: boolean;
  declare headerIsFocused: boolean;
  declare ballotEnabled: boolean;

  initialize(): void {
    if (this.advancedKeybindingsDisabled()) return;

    this.reset();
    this.addListeners();
  }

  public handleEnter(event: Event) {
    if (this.advancedKeybindingsDisabled() || this.answerInputIsFocused) return;

    event.preventDefault();

    if (this.pressedLetters.length > 0) {
      return this.handleFocusQuestion();
    }

    if (this.pressedNumbers.length > 0) {
      return this.handleCheckAnswer();
    }

    if (this.headerIsFocused) {
      return this.handleHeaderFocused();
    }

    const nextIndex = this.focusedQuestionIndex + 1;

    if (nextIndex < this.questionsCountValue) {
      return this.emitFocusQuestionByIndex(nextIndex);
    }

    if (this.submitHasFocus()) {
      this.submit();
    } else {
      this.focusSubmit();
    }
  }

  public handleDelete(event: Event) {
    if (this.advancedKeybindingsDisabled() || this.answerInputIsFocused) return;

    event.preventDefault();

    if (this.pressedLetters.length > 0) {
      return this.resetPressedLetters(true);
    }

    if (this.pressedNumbers.length > 0) {
      return this.resetPressedNumbers(true);
    }

    if (this.submitHasFocus()) {
      this.removeFocusSubmit();
      return this.emitFocusQuestionByIndex(this.focusedQuestionIndex);
    }

    const previousIndex = this.focusedQuestionIndex - 1;

    if (previousIndex >= 0) {
      return this.emitFocusQuestionByIndex(previousIndex);
    } else {
      return this.emitFocusQuestionByIndex(0);
    }
  }

  // private methods

  private advancedKeybindingsDisabled() {
    return !this.advancedKeybindingActiveValue;
  }

  private reset() {
    this.ballotEnabled = true;
    this.headerIsFocused = false;

    this.resetPressedNumbers();
    this.resetPressedLetters();
  }

  private headerIsPresent() {
    return Boolean(document.getElementById(`${HEADER_UNIQUE_ID}`));
  }

  private handleHeaderFocused() {
    this.emitUnfocusHeader();
    this.headerIsFocused = false;

    if (this.ballotEnabled) {
      this.emitFocusQuestionByIndex(0);
    } else {
      this.focusSubmit();
    }
  }

  private initialFocus() {
    if (this.advancedKeybindingsDisabled()) return;

    if (this.headerIsPresent()) {
      this.emitFocusHeader();
      this.headerIsFocused = true;
    } else {
      this.emitFocusQuestionByIndex(0);
    }
  }

  private handleCheckAnswer() {
    this.emitCheckAnswerByNumber(this.pressedNumbersJoined());
    this.resetPressedNumbers();
  }

  private handleFocusQuestion() {
    if (!this.focusableQuestion()) {
      this.emitQuestionNotFound();
      this.resetPressedLetters();
      return;
    }

    this.emitUnfocusHeader();
    this.emitFocusQuestionByLetter(this.pressedLettersJoined());
    this.emitHideKeyPressed();
    this.resetPressedLetters();
    this.resetPressedNumbers();
  }

  private focusableQuestion() {
    const index = letterToIndex(this.pressedLettersJoined());

    return index <= this.questionsCountValue;
  }

  private resetPressedNumbers(emit = false) {
    this.pressedNumbers = [];

    if (emit) {
      this.emitNumberCleared();
    }
  }

  private pressedNumbersJoined() {
    return Number(this.pressedNumbers.join(''));
  }

  private resetPressedLetters(emit = false) {
    this.pressedLetters = [];

    if (emit) {
      this.emitHideKeyPressed();
    }
  }

  private pressedLettersJoined() {
    return this.pressedLetters.join('');
  }

  private submitHasFocus() {
    return this.submitButtonTarget === document.activeElement;
  }

  private focusSubmit() {
    if (!this.hasSubmitButtonTarget) return;

    this.emitClearFocus();

    this.submitButtonTarget.focus();
  }

  private removeFocusSubmit() {
    this.submitButtonTarget.blur();
  }

  private submit() {
    if (!this.hasSubmitButtonTarget) return;

    this.submitButtonTarget.click();
  }

  private handleNumberPressed(key: string) {
    this.pressedNumbers.push(Number(key));
    this.emitNumberPressed(this.pressedNumbersJoined());
  }

  private handleLetterPressed(key: string) {
    this.pressedLetters.push(key.toUpperCase());
    this.emitShowKeyPressed(this.pressedLettersJoined());
  }

  private keyPressed(event: KeyboardEvent) {
    if (this.answerInputIsFocused) return;

    const { key } = event;

    if (key.match(/[0-9]/)) {
      return this.handleNumberPressed(key);
    }

    if (key.match(/^[a-z]$/i)) {
      return this.handleLetterPressed(key);
    }
  }

  private questionFocused({ orderIndex }) {
    this.focusedQuestionIndex = orderIndex;
  }

  private updateAnswerInputFocused(value: boolean) {
    this.answerInputIsFocused = value;
  }

  private updateBallotEnabled(value: boolean) {
    this.ballotEnabled = value;
  }

  // Listeners

  private addListeners() {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      this.keyPressed(event);
    });

    document.addEventListener(QUESTION_EVENTS.QUESTION_FOCUSED, (event) => {
      this.questionFocused(event.detail);
    });

    document.addEventListener(QUESTION_EVENTS.ANSWER_INPUT_FOCUSED, () => {
      this.updateAnswerInputFocused(true);
    });

    document.addEventListener(QUESTION_EVENTS.ANSWER_INPUT_UNFOCUSED, () => {
      this.updateAnswerInputFocused(false);
    });

    document.addEventListener(HEADER_EVENTS.BALLOT_DISABLED, () => {
      this.updateBallotEnabled(false);
    });

    document.addEventListener(HEADER_EVENTS.BALLOT_ENABLED, () => {
      this.updateBallotEnabled(true);
    });

    window.addEventListener('load', () => {
      this.initialFocus();
    });
  }

  // Emitters

  private emitCheckAnswerByNumber(number: number) {
    this.dispatch(EVENTS.CHECK_ANSWER_BY_NUMBER, {
      detail: {
        number: number,
      },
    });
  }

  private emitFocusQuestionByIndex(index: number) {
    this.dispatch(EVENTS.FOCUS_QUESTION_BY_INDEX, {
      detail: {
        index: index,
      },
    });
  }

  private emitNumberPressed(number: number) {
    this.dispatch(EVENTS.NUMBER_PRESSED, {
      detail: {
        number,
      },
    });
  }

  private emitNumberCleared() {
    this.dispatch(EVENTS.NUMBER_CLEARED);
  }

  private emitFocusQuestionByLetter(letter: string) {
    this.dispatch(EVENTS.FOCUS_QUESTION_BY_LETTER, {
      detail: {
        letter,
      },
    });
  }

  private emitClearFocus() {
    this.dispatch(EVENTS.CLEAR_FOCUS);
  }

  private emitFocusHeader() {
    this.dispatch(EVENTS.FOCUS_HEADER);
  }

  private emitUnfocusHeader() {
    this.dispatch(EVENTS.UNFOCUS_HEADER);
  }

  private emitShowKeyPressed(key) {
    this.dispatch(KEY_OVERLAY_EVENTS.SHOW, {
      detail: {
        key: key,
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitHideKeyPressed() {
    this.dispatch(KEY_OVERLAY_EVENTS.HIDE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitQuestionNotFound() {
    this.dispatch(KEY_OVERLAY_EVENTS.SHAKE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }
}
