import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectInput', 'editButton'];

  declare selectInputTarget: HTMLInputElement;
  declare editButtonTarget: HTMLButtonElement;

  public changeSelectedOrganization (event: Event) {
    const orgId = this.selectInputTarget.value;
    const adminLink = document.querySelector('#edit_org_link') as HTMLAnchorElement;
    const primaryContactLink = document.querySelector('#election_primary_contact > a') as HTMLAnchorElement;
    const orgLocationLink = document.querySelector('.organization-location > a') as HTMLAnchorElement;

    this.updateLinkHref(this.editButtonTarget, orgId);
    this.updateLinkHref(adminLink, orgId);
    this.updateLinkHref(primaryContactLink, orgId);
    this.updateLinkHref(orgLocationLink, orgId);
  }

  private updateLinkHref(selector: string, orgId: string) {
    if (selector) {
      let url = selector.getAttribute('href');
      url = url.replace(/\d+/, orgId);
      selector.setAttribute('href', url);
    }
  }
}
