// app/javascript/controllers/ballots/points_tally_component/points_tally_controller.js

import { Controller } from 'stimulus'
import PluralityQuestion from './plurality_question'
import PreferentialQuestion from './preferential_question'
import ApprovalQuestion from './approval_question'
import ScoredQuestion from './scored_question'
import CumulativeQuestion from './cumulative_question'

export default class extends Controller {
  static values = {
    id: Number,
    min: Number,
    max: Number,
    kind: String,
    availableVotes: Number,
    allowIncomplete: Boolean,
  }

  connect() {
    this.check()
    this.listenToAnswers()
  }

  check(event='initial') {
    const errorMessage = document.querySelector(".question > div.message.message--error")
    this.question = this.buildQuestion()
    this.checkAbstained()
    if(event !== 'initial' || errorMessage != null || this.question.hasSelections) {
      this.validateSelection()
    }
    this.updatePointsUsed()
    this.updateChoicesUsed()
  }

  buildQuestion() {
    if (this.kindValue === 'plurality') {
      return new PluralityQuestion(this.idValue, this.minValue, this.maxValue)
    }
    if (this.kindValue === 'preferential') {
      return new PreferentialQuestion(this.idValue, this.minValue, this.maxValue)
    }
    if (this.kindValue === 'approval') {
      return new ApprovalQuestion(this.idValue, this.minValue, this.maxValue)
    }
    if (this.kindValue === 'scored') {
      return new ScoredQuestion(this.idValue, this.minValue, this.maxValue)
    }
    if (this.kindValue === 'cumulative') {
      return new CumulativeQuestion(this.idValue, this.minValue, this.maxValue, this.availableVotesValue, this.allowIncompleteValue)
    }

    throw new Error('Not recognized type of question')
  }

  checkAbstained() {
    this.element.hidden = this.question.checkIfAbstained()
  }

  validateSelection() {
    if (this.question.outOfRange()) {
      this.element.classList.remove('neutral')
      this.element.classList.remove('good')
      this.element.classList.add('error')
    } else {
      this.element.classList.remove('neutral')
      this.element.classList.remove('error')
      this.element.classList.add('good')
    }
  }

  updatePointsUsed() {
    document.querySelector(`#question_${this.question.id} .points-used`).innerText = this.question.answered
  }

  updateChoicesUsed() {
    if (this.kindValue === 'cumulative') {
      document.querySelector(`#question_${this.question.id} .used-choices`).innerText = this.question.choices
    }
  }

  listenToAnswers() {
    const inputs = document.querySelectorAll(this.question.answerListSelectorToListenTo)
    inputs.forEach((input) => {
      input.addEventListener('change', this.check.bind(this))
    })
    const abstentionCheck = document.querySelector(this.question.abstentionSelector)
    if (!abstentionCheck) return
    abstentionCheck.addEventListener('change', this.check.bind(this))
  }
}
