import BaseQuestion from './base_question'

export default class PluralityQuestion extends BaseQuestion {
  get answerListSelector() {
    return `#question_${this.id} input[type=radio], #question_${this.id} input[type=checkbox]`
  }

  // eslint-disable-next-line class-methods-use-this
  responseToAnswer(element) {
    return element.checked
  }
}
