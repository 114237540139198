import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['meetingVoteInstructionsContainer']

  changeToBallotAccess(event) {
    if (event.target.value === 'true') {
      this.meetingVoteInstructionsContainerTarget.classList.add('hide')
    } else {
      this.meetingVoteInstructionsContainerTarget.classList.remove('hide')
    }
  }
}
