import { Controller } from 'stimulus'

export default class extends Controller {
  copy(event) {
    this.copyToClipboard(this.element.dataset.textToCopy.trim())

    clearTimeout(this.timeout)
    this.timeout = setTimeout(this._setInnerHTMLFunc(this.element, this.element.innerHTML), 1000);
    const alternateContent = this.element.dataset.copiedAlternate ? this.htmlDecode(this.element.dataset.copiedAlternate) : 'Copied!';
    this.element.innerHTML = alternateContent;
  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  copyToClipboard(text) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
    } else {
      // IE 11 or browsers that do not support the clipboard api
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('Copy')
      textArea.remove()
    }
  }

  _setInnerHTMLFunc(target, html) {
    return function() {
      target.innerHTML = html;
    };
  }
}
