import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['hideSettingsButton', 'dirtyInput']

  declare readonly hideSettingsButtonTarget: HTMLButtonElement
  declare readonly dirtyInputTarget: HTMLInputElement

  initialize(): void {
    this.hideTextNode = document.createTextNode('Hide Settings')
    this.showTextNode = document.createTextNode('Show Settings')
  }

  backButton(event): void {
    event.preventDefault()

    if (this.dirtyInputTarget.value === 'true') {
      if (confirm('You have unsaved changes, do you want to save them?')) {
        this.saveSettingsButton(event)
      }
    }

    window.top.location = event.target.getAttribute('href')
  }

  saveSettingsButton(event): void {
    let type = `${this.identifier}:saveSettings`

    event.preventDefault()
    this.dirtyInputTarget.value = false
    event.target.classList.add('disabled')

    this.element.dispatchEvent(new CustomEvent(type, { bubbles: true }))
  }

  optionsToggleButton(event): void {
    let type = `${this.identifier}:toggleSettings`
    let showHideStatus = this.hideSettingsButtonTarget.dataset.showHideStatus
    let newShowHideStatus = (showHideStatus == 'show') ? 'hide' : 'show'

    const replacementButtonTextNode = (newShowHideStatus == 'show') ? this.hideTextNode : this.showTextNode
    const buttonExistingTextNode = [...this.hideSettingsButtonTarget.childNodes].filter(i=> i.nodeType == Node.TEXT_NODE)[0]

    event.preventDefault()
    this.hideSettingsButtonTarget.replaceChild(replacementButtonTextNode, buttonExistingTextNode)
    this.hideSettingsButtonTarget.dataset.showHideStatus = newShowHideStatus

    this.element.dispatchEvent(
      new CustomEvent(
        type, {
          detail: {
            showHideStatus: newShowHideStatus
          },
          bubbles: true
        }
      )
    )
  }

  shareButton(event): void {
    if (this.dirtyInputTarget.value === 'true') {
      event.preventDefault()
      alert('Please save your changes first before clicking Share')
    } else {
      NProgress.start()
    }
  }

  printButton(event): void {
    let title = document.title

    event.preventDefault()
    document.title = "Voting Results"
    window.print()
    document.title = title
  }

  downloadReportButton(event): void {
    event.preventDefault()
    if (this.dirtyInputTarget.value === 'true') {
      alert('Please save your changes first before clicking Download')
    } else {
      window.top.location = event.target.getAttribute('href')
    }
  }

  preventDownloadReportOnResultsAccessNotAllowed(event): void {
    event.preventDefault()
    alert('You can only download the report after the vote is completed')
  }
}