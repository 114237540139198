
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets: string[] = [
    'form',
    'organizationKeyLabel',
    'input',
    'editButton',
    'cancelButton',
    'submitButton',
    'errorContainer',
    'copyUrlButton',
    'copyQrButton'
  ]

  declare FormTarget: HTMLFormElement;
  declare organizationKeyLabelTarget: HTMLLabelElement;
  declare inputTarget: HTMLInputElement;
  declare editButtonTarget: HTMLButtonElement;
  declare cancelButtonTarget: HTMLButtonElement;
  declare submitButtonTarget: HTMLButtonElement;
  declare errorContainerTarget: HTMLElement;
  declare copyUrlButtonTarget: HTMLElement;
  declare copyQrButtonTarget: HTMLElement;

  public clickEditButton(event: Event) {
    event.preventDefault();
    this.organizationKeyLabelTarget.classList.add('hide');
    this.inputTarget.value = this.organizationKeyLabelTarget.innerText.trim();
    this.inputTarget.classList.remove('hide');
    this.editButtonTarget.classList.add('hide');
    this.submitButtonTarget.classList.remove('hide');
    this.cancelButtonTarget.classList.remove('hide');
    this.copyUrlButtonTarget.classList.add('hide');
    this.copyQrButtonTarget.classList.add('hide');
  }

  public clickCancelButton(event: Event) {
    event.preventDefault();
    this.organizationKeyLabelTarget.classList.remove('hide');
    this.inputTarget.classList.add('hide');
    this.editButtonTarget.classList.remove('hide');
    this.submitButtonTarget.classList.add('hide');
    this.cancelButtonTarget.classList.add('hide');
    this.errorContainerTarget.innerHTML = '';
    this.copyUrlButtonTarget.classList.remove('hide');
    this.copyQrButtonTarget.classList.remove('hide');
  }
}
