import { Controller } from 'stimulus'
import I18n from 'i18n-js/index.js.erb';

export default class extends Controller {
  static targets = [
    'fullscreenButton',
    'answerForm',
    'uploadImageButton',
    'imageInput',
    'dragHelpText',
    'resizeHelpText',
    'uploadPdfButton',
    'pdfFileName',
    'removePdfButton',
    'cropX',
    'cropY',
    'cropW',
    'cropH'
  ]

  declare fullscreenButtonTarget: HTMLButtonElement;
  declare answerFormTarget: HTMLFormElement;
  declare uploadImageButtonTarget: HTMLButtonElement;
  declare imageInputTarget: HTMLImageElement;
  declare dragHelpTextTarget: HTMLElement;
  declare resizeHelpTextTarget: HTMLElement;
  declare uploadPdfButtonTarget: HTMLButtonElement;
  declare pdfFileNameTarget: HTMLElement;
  declare removePdfButtonTarget: HTMLElement;
  declare cropXTarget: HTMLInputElement;
  declare cropYTarget: HTMLInputElement;
  declare cropWTarget: HTMLInputElement;
  declare cropHTarget: HTMLInputElement;

  connect(): void {
    EB.TextEditor.init();

    const answerPicture = this.element.querySelector('#answer_picture, #answer_draft_picture');

    if (answerPicture) {
      answerPicture.addEventListener('change', this.enableCropImage.bind(this));
    }

    this.answerFormTarget.addEventListener('turbo:submit-end', event => this.handleSubmitResponse(event));

    document.addEventListener('turbo:before-fetch-response', (event) => {
      const xFlag = event.detail.fetchResponse.response.headers.get('X-Flag');

      if (xFlag) {
        this.closeModal();
      }
    });
  }

  public imageInputChange(event: Event): void {
    const maxFileSize = parseInt(this.imageInputTarget.dataset.maxFileSize || '0', 10);

    const file = (event.target as HTMLInputElement).files?.[0];
    if (file && file.size > maxFileSize) {
      document.querySelector('.image-help-text').style.color = 'red';
      this.imageInputTarget.value = '';
    }
    else {
      document.querySelector('.image-help-text').style.color = '';
    }
  }

  public toggleFullscreen(event: Event): void {
    const modal = document.querySelector('.turbo-modal-component__modal');
    const textElement = this.fullscreenButtonTarget.querySelector('span');
    event.preventDefault();

    if(modal.classList.contains('fullscreen')){
      modal.classList.remove('fullscreen');
      textElement.textContent = 'Fullscreen'
    }
    else{
      modal.classList.add('fullscreen');
      textElement.textContent = 'Exit Fullscreen'
    }
  }

  public closeModal(): void {
    const closeModalButton = document.querySelector('.turbo-modal-component__close-modal');

    if(closeModalButton) {
      closeModalButton.click();
    }
  }

  public enableCropImage(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;

    if (window.FileReader && input) {
      if (!input.files || !input.files[0]) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result as string;
        const cropImageElement = document.querySelector('#crop_image');
        if (!cropImageElement) {
          return;
        }

        // remove existing croppie, if any
        this.removeCroppie(cropImageElement);

        // Remove any image tag and event listeners
        cropImageElement.innerHTML = '';

        // Initialize croppie
        const uploadCrop = new Croppie(cropImageElement, {
          viewport: {
            width: 100,
            height: 100,
            type: 'circle'
          },
          boundary: {
            width: 100,
            height: 120
          }
        });

        // Bind file upload image to croppie
        uploadCrop.bind({
          url: result,
          zoom: 0
        });

        // Listen for changes on crop to update coordinates

        uploadCrop.element.addEventListener('update', () => {
          this.updateCropCoordinates(uploadCrop);
        });
      };

      reader.readAsDataURL(input.files[0]);

      // Reveal help text
      this.updateText();
    } else {
      // Only upload the file to get the thumbnail if file reader is not supported
      this.submitImage();
    }
  }

  private removeCroppie(element: Element): void {
    const existingCroppie = element.dataset.croppie;
    if (existingCroppie) {
      existingCroppie.destroy();
    }
  }

  private updateCropCoordinates(element: Element): void {
    const points = element.get().points;
    this.cropXTarget.value = points[0];
    this.cropYTarget.value = points[1];
    // We are cropping a square, so witdh and height are the same
    this.cropWTarget.value = points[2] - points[0];
    this.cropHTarget.value = points[3] - points[1];
  }

  private updateText(): void {
    this.dragHelpTextTarget.classList.remove('hide');
    this.resizeHelpTextTarget.classList.remove('hide');
    this.uploadImageButtonTarget.textContent = I18n.t('pages.vote.ballot.question.nomination.profile.edit_image');
  }

  private submitImage(): void {
    if (this.answerFormTarget) {
      Rails.fire(this.answerFormTarget, 'submit');
    }
  }

  private changePdfInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const filename = input.files[0].name;
      this.pdfFileNameTarget.textContent = filename;
      this.uploadPdfButtonTarget.textContent = I18n.t('pages.vote.ballot.question.nomination.profile.edit_pdf');
      this.removePdfButtonTarget.classList.remove('hide');
    }
  }

  private handleSubmitResponse(event): void {
    const modal = document.querySelector('.questions--answers--candidate-profile-modal');

    this.element.parentElement.removeAttribute("src");
    modal.remove();
  }

  private enableTurboForm(event: Event): void {
    this.answerFormTarget.setAttribute('data-turbo', 'true');
  }
}
