import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button']

  declare buttonTarget: HTMLButtonElement;

  public enableSubmit(event: Event): void {
    if(event.target.value.length > 0)
      this.buttonTarget.disabled = false;
  }
}
