import { mapValues } from 'lodash';
import { EVENTS as FORM_EVENTS } from '../form_component/form_component_controller';
import { EVENTS as KEY_OVERLAY_EVENTS } from '../key_overlay_component/key_overlay_component_controller';
import { BaseController } from '../../base_controller';

export const UNIQUE_ID = 'ballots--header-component';

export const EVENTS = (() => {
  const events = {
    BALLOT_DISABLED: 'ballot_disabled',
    BALLOT_ENABLED: 'ballot_enabled',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export default class extends BaseController {
  static targets = [
    'quorumRadioButton',
    'proxyVoteToMemberRadioButton',
    'proxyVoteToAdminRadioButton',
    'container',
    'keyOverlay',
    'option',
  ];

  declare quorumRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToMemberRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToAdminRadioButtonTarget: HTMLInputElement;
  declare proxyVoteToMemberSelectorComponent: HTMLElement;
  declare containerTarget: HTMLElement;
  declare questionsElements: Element[];
  declare focused: boolean;
  declare keyOverlayTarget: HTMLElement;
  declare optionTargets: HTMLInputElement[];

  static values = {
    keyOverlayIdentifier: String,
  };

  declare keyOverlayIdentifierValue: string;

  initialize(): void {
    this.addListeners();
    this.populateVariables();
    this.initialChecks();
  }

  public enableQuorum(): void {
    this.disableBallot();
    this.disableProxyVoteToMember();
  }

  public enableVote(): void {
    this.enableBallot();
    this.disableProxyVoteToMember();
  }

  public enableProxyVoteToAdmin(): void {
    this.disableBallot();
  }

  public enableProxyVoteToMember(): void {
    this.disableBallot();
    this.proxyVoteToMemberSelectorComponent.classList.remove('hide');
  }

  // Private methods

  private populateVariables() {
    this.questionsElements = Array.from(document.querySelectorAll('.question'));
    this.proxyVoteToMemberSelectorComponent = document.querySelector(
      '.ballots-proxy-voter-component'
    )!;
  }

  private initialChecks() {
    if (
      document.querySelector('#vote_mode_register_quorum') &&
      this.quorumRadioButtonTarget.checked
    )
      this.disableBallot();

    if (
      document.querySelector('#vote_mode_proxy_vote_to_member') &&
      this.proxyVoteToMemberRadioButtonTarget.checked
    )
      this.enableProxyVoteToMember();

    if (
      document.querySelector('#vote_mode_proxy_vote_to_admin') &&
      this.proxyVoteToAdminRadioButtonTarget.checked
    )
      this.enableProxyVoteToAdmin();
  }

  private disableProxyVoteToMember(): void {
    this.proxyVoteToMemberSelectorComponent.classList.add('hide');
  }

  private disableBallot(): void {
    // TODO, this change should be done by question_component
    this.questionsElements.forEach((i) => i.classList.add('hide'));
    this.emitBallotDisabled();
  }

  private enableBallot() {
    this.questionsElements.forEach((i) => i.classList.remove('hide'));
    this.emitBallotEnabled();
  }

  private focus() {
    this.containerTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    this.containerTarget.classList.add('focus');
    this.focused = true;
  }

  private removeFocus() {
    this.containerTarget.classList.remove('focus');
    this.focused = false;
    this.emitHideKeyPressed();
  }

  private checkAnswerByNumber({ number: answerReferenceIndex }) {
    if (!this.focused) return;

    const option = this.optionTargets.find(
      (el) => el.id === `header_option_${answerReferenceIndex}`
    );

    if (!option) return this.emitNotifyAnswerNotFound();

    this.emitHideKeyPressed();

    option.focus();
    option.click();
  }

  // Listeners

  private addListeners() {
    document.addEventListener(FORM_EVENTS.FOCUS_HEADER, () => {
      this.focus();
    });

    document.addEventListener(FORM_EVENTS.UNFOCUS_HEADER, () => {
      this.removeFocus();
    });

    document.addEventListener(FORM_EVENTS.NUMBER_PRESSED, (event) => {
      this.emitShowKeyPressed(event.detail.number);
    });

    document.addEventListener(FORM_EVENTS.NUMBER_CLEARED, () => {
      this.emitHideKeyPressed();
    });

    document.addEventListener(FORM_EVENTS.CHECK_ANSWER_BY_NUMBER, (event) => {
      this.checkAnswerByNumber(event.detail);
    });
  }

  // Emitters

  private emitBallotDisabled() {
    this.dispatch(EVENTS.BALLOT_DISABLED);
  }

  private emitBallotEnabled() {
    this.dispatch(EVENTS.BALLOT_ENABLED);
  }

  private emitNotifyAnswerNotFound() {
    this.dispatch(KEY_OVERLAY_EVENTS.SHAKE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitShowKeyPressed(key) {
    if (!this.focused) return;

    this.dispatch(KEY_OVERLAY_EVENTS.SHOW, {
      detail: {
        key: key,
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }

  private emitHideKeyPressed() {
    this.dispatch(KEY_OVERLAY_EVENTS.HIDE, {
      detail: {
        identifier: this.keyOverlayIdentifierValue,
      },
    });
  }
}
