import { BaseController } from '../../../base_controller';
import { Turbo } from '@hotwired/turbo-rails';
import { mapValues } from 'lodash';
import { requestHTML } from '../../../utils/turbo';
import { EVENTS as LIST_EVENTS } from '../result_list_component/result_list_component_controller';

const UNIQUE_ID = 'votes--dashboard--vote-item-component';

export const EVENTS = (() => {
  const events = {
    CLOSE_ALL: 'close_all',
    CHECK: 'check',
    UNCHECK: 'uncheck',
    DISABLED_STATUS: 'disabled_status',
  };

  return mapValues(events, (name) => `${UNIQUE_ID}:${name}`);
})();

export const findVoteItemElements = () => {
  const elements = document.querySelectorAll<HTMLInputElement>(
    '[id^=vote_item_check_]'
  );

  return Array.from(elements);
};

export default class extends BaseController {
  static targets = ['actionsMenu', 'loadingActionsMenu', 'checkboxInput'];

  static values = {
    actionsUrl: String,
    turboStreamId: String,
  };

  declare actionsMenuTarget: HTMLUListElement;
  declare loadingActionsMenuTarget: HTMLElement;
  declare checkboxInputTarget: HTMLInputElement;
  declare actionsUrlValue: string;
  declare turboStreamIdValue: string;

  initialize(): void {
    this.addListeners();
  }

  async toggleActions(event: Event) {
    this.emitCloseEvent();

    const target = event.target as HTMLInputElement;

    if (target.checked) {
      this.emitCheck();

      await this.handleShow();
    } else {
      this.closeMenu();
      this.emitUncheck();
    }
  }

  private async handleShow() {
    try {
      this.emitDisabledStatus(true);
      this.showLoading();

      await this.getActions();

      this.showMenu();
    } catch (error) {
      console.error(error);
      alert('An error occurred');
    } finally {
      this.hideLoading();
      this.emitDisabledStatus(false);
    }
  }

  private handleListMultiActions() {
    if (this.isFirstElement()) {
      this.emitCloseEvent();
      this.closeMenu();
      this.handleShow();
    }
  }

  private isFirstElement(): boolean {
    const elements = findVoteItemElements();

    const firstRowId = elements.filter((el) => el.checked)[0]?.id;

    return this.checkboxInputTarget.id === firstRowId;
  }

  private updateCheckboxValue(value: boolean) {
    this.checkboxInputTarget.checked = value;
  }

  private updateCheckboxDisabledStatus(value: boolean) {
    this.checkboxInputTarget.disabled = value;
  }

  private showLoading(): void {
    this.loadingActionsMenuTarget.classList.add('visible');
  }

  private hideLoading(): void {
    this.loadingActionsMenuTarget.classList.remove('visible');
  }

  private showMenu(): void {
    this.actionsMenuTarget.classList.add('visible');
  }

  private closeMenu(): void {
    this.actionsMenuTarget.classList.remove('visible');
  }

  private voteItemIds(): string[] {
    const elements = findVoteItemElements();

    return elements.filter((el) => el.checked).map((el) => el.value);
  }

  async getActions() {
    const params = {
      vote_item_ids: this.voteItemIds(),
      turbo_stream_id: this.turboStreamIdValue,
    };
    const html = await requestHTML(this.actionsUrlValue, params);

    Turbo.renderStreamMessage(html);
  }

  private skipClose(event: CustomEvent) {
    const { skipElementId } = event.detail;

    return skipElementId === this.element.id;
  }

  // Events

  private addListeners() {
    document.addEventListener(EVENTS.CLOSE_ALL, (event) => {
      if (this.skipClose(event as CustomEvent)) return;

      this.closeMenu();
    });

    document.addEventListener(EVENTS.DISABLED_STATUS, (event) => {
      this.updateCheckboxDisabledStatus(event.detail.value);
    });

    document.addEventListener(LIST_EVENTS.CHECK_ALL, () => {
      this.updateCheckboxValue(true);
    });

    document.addEventListener(LIST_EVENTS.UNCHECK_ALL, () => {
      this.updateCheckboxValue(false);
      this.closeMenu();
    });

    document.addEventListener(LIST_EVENTS.GET_MULTI_ACTIONS, () => {
      this.handleListMultiActions();
    });
  }

  private emitCloseEvent() {
    this.dispatch(EVENTS.CLOSE_ALL, {
      // @ts-ignore
      prefix: null,
      detail: {
        skipElementId: this.element.id,
      },
    });
  }

  private emitCheck() {
    this.dispatch(EVENTS.CHECK, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitUncheck() {
    this.dispatch(EVENTS.UNCHECK, {
      // @ts-ignore
      prefix: null,
    });
  }

  private emitDisabledStatus(value: boolean) {
    this.dispatch(EVENTS.DISABLED_STATUS, {
      // @ts-ignore
      prefix: null,
      detail: {
        value,
      },
    });
  }
}
